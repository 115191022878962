import {
    CREATE_DISCOUNT,
    CREATE_DISCOUNT_SUCCESS,
    CREATE_DISCOUNT_ERROR,
    GET_DISCOUNTS,
    GET_DISCOUNTS_SUCCESS,
    GET_DISCOUNTS_ERROR,
    DELETE_DISCOUNT,
    DELETE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_ERROR,
    UPDATE_DISCOUNT,
    UPDATE_DISCOUNT_SUCCESS,
    UPDATE_DISCOUNT_ERROR,
    GET_DISCOUNT_BY_ID,
    GET_DISCOUNT_BY_ID_SUCCESS,
    GET_DISCOUNT_BY_ID_ERROR,
    GET_DISCOUNT_BY_CODE,
    GET_DISCOUNT_BY_CODE_SUCCESS,
    GET_DISCOUNT_BY_CODE_ERROR,
    RESET_ERROR,
    
} from '../constants/adminDiscount';
import DiscountService from '../../services/DiscountService';

const discountService = new DiscountService();

export const createDiscount = (discountData, accessToken) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CREATE_DISCOUNT
            });
            const response = await discountService.createDiscount(discountData, accessToken);
            dispatch({
                type: CREATE_DISCOUNT_SUCCESS,
                discount: response
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: CREATE_DISCOUNT_ERROR,
                payload: error
            });
        }
    }
}

export const getDiscounts = (page = 0, perPage = 10, accessToken) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_DISCOUNTS,
            });
            const response = await discountService.getDiscounts(page, perPage, accessToken);
            dispatch({
                type: GET_DISCOUNTS_SUCCESS,
                discounts: response.discounts,
                total: response.total
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: GET_DISCOUNTS_ERROR,
                payload: error,
            })
        }
    }
}

export const getSearchDiscounts = (value, page, perPage, accessToken) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_DISCOUNTS,
            });
            const resp = await discountService.getSearchDiscounts(value, page, perPage, accessToken);
            dispatch({
                type: GET_DISCOUNTS_SUCCESS,
                total: resp.total,
                discounts: resp.discounts,
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: GET_DISCOUNTS_ERROR,
                payload: error,
            });
        }
    };
};

export const getDiscountById = (discountId, accessToken) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_DISCOUNT_BY_ID
            });
            const response = await discountService.getDiscountById(discountId, accessToken);
            dispatch({
                type: GET_DISCOUNT_BY_ID_SUCCESS,
                discount: response
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: GET_DISCOUNT_BY_ID_ERROR,
                payload: error
            });
        }
    }
}

export const getDiscountByCode = (discountCode) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_DISCOUNT_BY_CODE
            });
            const response = await discountService.getDiscountByCode(discountCode);
            dispatch({
                type: GET_DISCOUNT_BY_CODE_SUCCESS,
                discount: response
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: GET_DISCOUNT_BY_CODE_ERROR,
                payload: error
            });
        }
    }
}

export const updateDiscount = (discountId, updateDate, accessToken) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: UPDATE_DISCOUNT
            });
            const response = await discountService.updateDiscount(discountId, updateDate, accessToken);
            dispatch({
                type: UPDATE_DISCOUNT_SUCCESS,
                discount: response
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: UPDATE_DISCOUNT_ERROR,
                payload: error
            });
        }
    }
}

export const deleteDiscount = (discountId, accessToken) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: DELETE_DISCOUNT
            });
            await discountService.deleteDiscount(discountId, accessToken);
            dispatch({
                type: DELETE_DISCOUNT_SUCCESS
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: DELETE_DISCOUNT_ERROR,
                payload: error
            });
        }
    }
}

// FIXME currently not in use
export const resetError = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: RESET_ERROR
            });
        } catch (error) {
            console.error(error);
        }
    }
}
