import {
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_ERROR,
  TOGGLE_EDIT,
  SEND_INVOICE,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_ERROR,
  CLEAR_SEND_INVOICE,
} from "../constants/invoice";
import { UPDATE_STATUS_SUCCESS } from "../constants/adminBooking";
import InvoiceService from "../../services/InvoiceService";

const invoiceService = new InvoiceService();

/**
 * Gets the invoice given the booking id
 * @param {string} bookingId The booking id linked to the invoice
 * @param {string} accessToken The access token of the current user
 * @returns
 */
export const getInvoiceByBookingId = (bookingId, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: GET_INVOICE,
    });
    try {
      const resp = await invoiceService.getInvoiceByBookingId(
        bookingId,
        accessToken
      );
      dispatch({
        type: GET_INVOICE_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: GET_INVOICE_ERROR,
        payload: err,
      });
    }
  };
};

/**
 * Edit an invoice content
 * @param {string} invoiceId The invoice id
 * @param {obj} invoice the new invoice elements
 * @param {string} accessToken
 */
export const updateInvoice = (invoiceId, invoice, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_INVOICE,
    });
    try {
      const resp = await invoiceService.updateInvoice(invoiceId, invoice, accessToken);
      dispatch({
        type: UPDATE_INVOICE_SUCCESS,
        payload: resp,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_INVOICE_ERROR,
        payload: err,
      });
    }
  };
};

/**
 * Toggles the invoice edit page
 */
export const toggleInvoiceEdit = () => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_EDIT });
  };
};

/**
 * Sends the invoice to the booking owner and updates the booking status to invoiced
 * @param {string} bookingId The booking id linked to the invoice to be sent
 * @param {string} accessToken
 */
export const sendInvoiceEmail = (bookingId, accessToken) => {
  return async (dispatch, getState) => {
    const { geoReducer } = getState();
    dispatch({ type: SEND_INVOICE });
    try {
      const { rate } = geoReducer.tax;
      const resp = await invoiceService.sendInvoice(bookingId, rate, accessToken);
      if (resp) {
        dispatch({
          type: SEND_INVOICE_SUCCESS,
        });
        dispatch({
          type: UPDATE_STATUS_SUCCESS,
          id: bookingId,
          status: "invoiced",
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_INVOICE_ERROR,
        payload: err,
      });
    }
  };
};

/**
 * Downloads specific invoice as PDF
 * @param {Object} invoiceDetails invoice object with all the details of it, including changes
 * @param {string} accessToken
 */
export const downloadInvoice = (invoiceDetails, accessToken) => {
  return async(dispatch, getState) => {
    const { geoReducer } = getState();
    try {
      const { rate } = geoReducer.tax;
      const details = {
        ...invoiceDetails,
        rate
      }
      await invoiceService.downloadInvoice(details, accessToken);
    } catch(err) {
      dispatch({
        type: SEND_INVOICE_ERROR,
        payload: err,
      })
    }
  }
}

export const clearSendInvoice = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEND_INVOICE,
    });
  };
};
