import * as constants from '../constants/adminRate';
import RateService from '../../services/RateService';

const rateService = new RateService();

export const getRates = (page = 0, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_RATES,
      });
      const response = await rateService.getRates(page, perPage, accessToken);
      dispatch({
        type: constants.GET_RATES_SUCCESS,
        rates: response.data.rates,
        total: response.data.total,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: constants.GET_RATES_ERROR,
        payload: error,
      });
    }
  };
};

export const getRateById = (rateId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_RATE_BY_ID,
      });
      const response = await rateService.getRateById(rateId, accessToken);
      dispatch({
        type: constants.GET_RATE_BY_ID_SUCCESS,
        rate: response.data,
      });
    } catch (err) {
      dispatch({
        type: constants.GET_RATE_BY_ID_ERROR,
        payload: err,
      });
    }
  };
};

export const createRate = (rate, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.CREATE_RATE,
      });
      const response = await rateService.createRate(rate, accessToken);
      dispatch({
        type: constants.CREATE_RATE_SUCCESS,
        rate: response.data,
      });
    } catch (err) {
      dispatch({
        type: constants.CREATE_RATE_ERROR,
        payload: err,
      });
    }
  };
};

export const updateRate = (rateId, rate, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.UPDATE_RATE,
      });
      // eslint-disable-next-line unused-imports/no-unused-vars
      const response = await rateService.updateRate(rateId, rate, accessToken);
      dispatch({
        type: constants.UPDATE_RATE_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: constants.UPDATE_RATE_ERROR,
        payload: err,
      });
    }
  };
};

export const deleteRate = (rateId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.DELETE_RATE,
      });
      // eslint-disable-next-line unused-imports/no-unused-vars
      const response = await rateService.deleteRate(rateId, accessToken);
      dispatch({
        type: constants.DELETE_RATE_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: constants.DELETE_RATE_ERROR,
        payload: err,
      });
    }
  };
};
