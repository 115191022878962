import React from 'react';
import {
  Spinner
} from './styles';
import MainWrapper from '../MainWrapper';

const Loading = () => {

  return (
    <MainWrapper>
      <Spinner viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2" 
        />
      </Spinner>
    </MainWrapper>
  );
};

export default Loading;
