import axios from 'axios';

export default class MateService {
  /**
   * Gets the list of mates
   * @param {string} accessToken
   */
  getMate(accessToken, page, perPage) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mate?page=${page}&per_page=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((resp) => resp.data);
  }

  /**
     * Gets the list of mates
     * @param {string} mateId
     * @param {string} accessToken
     */
  getMateById(mateId, accessToken) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mate/${mateId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((resp) => resp.data);
  }
  /**
    
   */

  /**
   * Creates a mate 
   * @param {obj} mateData The data to create mate
   *                      mateID:{string} the mate from route service (Onfleet)
   *                      name:{string} the mate name
   *                      address:{string} the mate address
   *                      phone:{string} the mate phone
   *                      email:{string} the mate email
   *                      neq:{string} the mate neq
   *                      tps:{string} the mate tps
   *                      tvq:{string} the mate tvq
   * @param {string} accessToken The current user access token to ping the backend
   */
  createMate(mateData, accessToken) {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/mate`, mateData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  /**
   * Update a mate 
   * @param {obj} mateData The data to create mate
   *                      mateId: {Object ID} the mate id from mongo DB
   *                      mateID:{string} the mate from route service (Onfleet)
   *                      name:{string} the mate name
   *                      address:{string} the mate address
   *                      phone:{string} the mate phone
   *                      email:{string} the mate email
   *                      neq:{string} the mate neq
   *                      tps:{string} the mate tps
   *                      tvq:{string} the mate tvq
   * @param {string} accessToken The current user access token to ping the backend
   */
  updateMate(mateId, mateData, accessToken) {
    return axios.put(`${process.env.REACT_APP_BACKEND_URL}/mate/${mateId}`, mateData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
