import produce from 'immer';
import * as messageConstants from '../constants/adminMessages';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  languages: ['en'],
  messageTypes: [],
  templates: [],
  error: null,
  errorSave: null,
  viewedTemplate: null,
};

const adminMessageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case messageConstants.GET_TEMPLATE:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case messageConstants.GET_TEMPLATE_SUCCESS:
        draft.isLoading = false;
        draft.viewedTemplate = action.template;
        break;
      case messageConstants.GET_TEMPLATE_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;

      case messageConstants.GET_TEMPLATES:
        draft.isLoading = true;
        draft.error = null;
        draft.isSaved = false;
        break;
      case messageConstants.GET_TEMPLATES_SUCCESS:
        draft.isLoading = false;
        draft.templates = action.templates;
        draft.languages = action.languages;
        draft.messageTypes = action.messageTypes;
        break;
      case messageConstants.GET_TEMPLATES_ERROR:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case messageConstants.UPDATE_TEMPLATE:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.error = null;
        draft.errorSave = null;
        break;
      case messageConstants.UPDATE_TEMPLATE_SUCCESS:
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case messageConstants.UPDATE_TEMPLATE_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorSave = action.payload;
        break;
      default:
        return state;
    }

  });

export default adminMessageReducer;
