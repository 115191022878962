import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Wrapper,
  InfoWrapper,
  TitleText,
  ValueTextWrapper,
  CoinText,
  ValueText,
  Tax,
} from './styles';

const EstimateBar = ({ tax, editMode, tip }) => {
  const intl = useIntl();
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const hide = () => {
    setClicked(false);
    setHovered(false);
  };

  const handleHoverChange = (visible) => {
    setHovered(visible);
    setClicked(false);
  };

  const handleClickChange = (visible) => {
    setHovered(false);
    setClicked(visible);
  };

  const { estimate } = useSelector(({ bookingReducer, tempBookingReducer }) =>
    !editMode ? bookingReducer : tempBookingReducer,
  );

  return (
    <Wrapper>
      <InfoWrapper>
        <Tooltip
          placement="bottomRight"
          title={intl.formatMessage({ id: 'description.tooltip.estimatedPrice' })}
          trigger="hover"
          visible={hovered}
          onVisibleChange={handleHoverChange}>
          <Tooltip
            placement="bottomRight"
            title={<>{intl.formatMessage({ id: 'description.tooltip.estimatedPrice' })} <a onClick={hide}>{intl.formatMessage({ id: 'action.close' })}</a></>}
            trigger="click"
            visible={clicked}
            onVisibleChange={handleClickChange}>
            <TitleText>
              <FormattedMessage id="model.estimate" />
            </TitleText>
            <QuestionCircleTwoTone
              style={{ padding: '5px' }}
              twoToneColor="var(--primary-color)"
            />
            <ValueTextWrapper>
              <CoinText>$</CoinText>
              <ValueText>
                {estimate && (estimate.amount + (Number.parseFloat(tip) || 0)).toFixed(2)}{' '}
                {tax ? (
                  <Tax>
                    + ${estimate.tax.toFixed(2)} <FormattedMessage id="model.tax" />
                  </Tax>
                ) : null}
              </ValueText>
            </ValueTextWrapper>
          </Tooltip>
        </Tooltip>
      </InfoWrapper>
    </Wrapper>
  );
};

export default EstimateBar;
