import styled from 'styled-components';

export const ContentWrapper = styled.div`
  transform: scale(0.8);
  margin-top: -24px;

  @media (max-width: 768px) {
    transform: scale(1.1);
    margin-top: 0px;
  }
`;

export const TitleText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: var(--tertiary-color);
  margin: 4px;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    margin: 0px;
  }
`;

export const DescriptionWrapper = styled.div`
  max-width: 150px;
  margin-left: 16px;

  @media (max-width: 768px) {
    margin-left: 24px;
    max-width: 120px;
  }
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  text-align: center;
  color: #949494;
`;

export const CircleBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 176px;
  height: 176px;
  text-align: center;
  cursor: ${(props) =>
    `${
      (props.active || props.clickable) && !props.disabled
        ? 'pointer'
        : 'default'
    }`};
  margin: 0px 20px 0px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  border: ${(props) =>
    `${props.active ? '4px solid  var(--secondary-color)' : 'none'}`};

  border-radius: 50%;

  background: #fff;
  opacity: ${(props) => `${props.disabled ? 0.4 : 1}`};

  @media (max-width: 768px) {
    width: 170px;
    height: 170px;
  }
`;

export const ImageWrapperWithChildren = styled.img`
  height: 76px;
  width: 76px;
  margin-top: 18px;

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
  }
`;

export const ImageWrapper = styled.img`
  height: 66px;
  width: 66px;
  aspect-ratio: 1 / 1;

  @media (max-width: 768px) {
    height: 66px;
    width: 66px;
  }
`;

export const EmptyImageWrapper = styled.div`
  height: 76px;
  width: 76px;

  @media (max-width: 768px) {
    height: 70px;
    width: 70px;
  }
`;

export const CircleWrapper = styled.div`
  transform: ${(props) => `scale(${props.scale})`};
`;
