import axios from 'axios';

export default class PartnerService {
  /**
   * Gets the list of partners
   * @param {string} accessToken
   */
  getPartner(accessToken) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/partner`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((resp) => resp.data);
  }

  /**
     * Gets the list of partners
     * @param {string} partnerId
     * @param {string} accessToken
     */
  getPartnerById(partnerId, accessToken) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/partner/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((resp) => resp.data);
  }

  /**
   * Creates a partner 
   * @param {obj} partnerData The data to create partner
   *                      id {string} the partner id
   *                      name {string} the partner name
   *                      addresses {array[Address]} the address of the partner
   * @param {string} accessToken The current user access token to ping the backend
   */
  createPartner(partnerData, accessToken) {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/partner`, partnerData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  /**
   * Update a partner 
   * @param {obj} partnerData The data to create partner
   *                      partnerId {string} the partner id
   *                      name {string} the partner name
   *                      addresses {array[Address]} the address of the partner
   * @param {string} accessToken The current user access token to ping the backend
   */
  updatePartner(partnerId, partnerData, accessToken) {
    return axios.put(`${process.env.REACT_APP_BACKEND_URL}/partner/${partnerId}`, partnerData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
