import React from 'react';
import PropTypes from 'prop-types';
import {
  CircleWrapper,
  CircleBtn,
  ContentWrapper,
  ImageWrapper,
  EmptyImageWrapper,
  ImageWrapperWithChildren,
  TitleText,
  DescriptionWrapper,
  DescriptionText,
} from './styles';

import './styles.css';

const CircularButton = ({
  type,
  scale,
  clickable,
  active,
  disabled,
  onClick,
  imagePath,
  title,
  description,
  children,
  customClass,
}) => {
  const renderNormalContent = () => {
    return (
      <>
        {imagePath ? (
          <ImageWrapper
            src={imagePath}
            alt={title}
          />
        ) : (
          <EmptyImageWrapper />
        )}
        <TitleText>{title}</TitleText>
        <DescriptionText>{description}</DescriptionText>
      </>
    );
  };

  const renderContentWithChildren = () => {
    return (
      <>
        <ContentWrapper>
          <ImageWrapperWithChildren
            src={imagePath}
            alt={title}
          />
          <TitleText>{title}</TitleText>
          <DescriptionWrapper>
            <DescriptionText>{description}</DescriptionText>
          </DescriptionWrapper>
        </ContentWrapper>
        {children}
      </>
    );
  };

  const renderOnlyChildrenContent = () => {
    return <>{children}</>;
  };

  const renderContent = () => {
    if (type === 'withChildren') {
      return renderContentWithChildren();
    } else if (type === 'onlyChildren') {
      return renderOnlyChildrenContent();
    }

    return renderNormalContent();
  };

  return (
    <div className={customClass}>
      <CircleWrapper scale={scale}>
        <CircleBtn
          active={active}
          disabled={disabled}
          clickable={clickable}
          onClick={() => onClick()}
        >
          {renderContent()}
        </CircleBtn>
      </CircleWrapper>
    </div>
  );
};

CircularButton.defaultProps = {
  type: 'normal',
  title: '',
  disabled: false,
  scale: 1,
  clickable: false,
  imagePath: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
};

CircularButton.propTypes = {
  type: PropTypes.oneOf(['normal', 'withChildren', 'onlyChildren']),
  scale: PropTypes.number,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  imagePath: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customClass: PropTypes.string,
};

export default CircularButton;
