const DOCUMENT = 'RATE_';

export const CREATE_RATE = DOCUMENT + 'CREATE_RATE';
export const CREATE_RATE_SUCCESS = DOCUMENT + 'CREATE_RATE_SUCCESS';
export const CREATE_RATE_ERROR = DOCUMENT + 'CREATE_RATE_ERROR';
export const GET_RATES = DOCUMENT + 'GET_RATES';
export const GET_RATES_SUCCESS = DOCUMENT + 'GET_RATES_SUCCESS';
export const GET_RATES_ERROR = DOCUMENT + 'GET_RATES_ERROR';
export const DELETE_RATE = DOCUMENT + 'DELETE_RATE';
export const DELETE_RATE_SUCCESS = DOCUMENT + 'DELETE_RATE_SUCCESS';
export const DELETE_RATE_ERROR = DOCUMENT + 'DELETE_RATE_ERROR';
export const GET_RATE_BY_ID = DOCUMENT + 'GET_RATE_BY_ID';
export const GET_RATE_BY_ID_SUCCESS = DOCUMENT + 'GET_RATE_BY_ID_SUCCESS';
export const GET_RATE_BY_ID_ERROR = DOCUMENT + 'GET_RATE_BY_ID_ERROR';
export const UPDATE_RATE = DOCUMENT + 'UPDATE_RATE';
export const UPDATE_RATE_SUCCESS = DOCUMENT + 'UPDATE_RATE_SUCCESS';
export const UPDATE_RATE_ERROR = DOCUMENT + 'UPDATE_RATE_ERROR';
export const RESET_ERROR = DOCUMENT + 'RESET_ERROR';
