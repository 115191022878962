const DOCUMENT = 'MESSAGE_';

export const GET_TEMPLATES = DOCUMENT + 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = DOCUMENT + 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_ERROR = DOCUMENT + 'GET_TEMPLATES_ERROR';
export const GET_TEMPLATE = DOCUMENT + 'GET_TEMPLATE';
export const GET_TEMPLATE_SUCCESS = DOCUMENT + 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_ERROR = DOCUMENT + 'GET_TEMPLATE_ERROR';
export const UPDATE_TEMPLATE = DOCUMENT + 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = DOCUMENT + 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_ERROR = DOCUMENT + 'UPDATE_TEMPLATE_ERROR';
