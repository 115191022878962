import produce from 'immer';
import {
  COLLAPSE_CHANGE,
  COLLAPSE_OPEN_DRAWER,
  CHANGE_OPEN_KEYS,
  TOGGLE_ALL,
  CHANGE_CURRENT,
  CLEAR_MENU,
} from '../constants/adminApp';
import {
  getDefaultPath,
  getView,
  isServer,
} from '../../utils/adminDashboardUtil';

const preKeys = getDefaultPath();
const initialState = {
  collapsed: !(!isServer && window.innerWidth > 1220),
  view: !isServer && getView(window.innerWidth),
  height: !isServer && window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
};

const adminAppReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COLLAPSE_CHANGE:
        draft.collapsed = !state.collapsed;
        break;
      case COLLAPSE_OPEN_DRAWER:
        draft.openDrawer = !state.openDrawer;
        break;
      case TOGGLE_ALL:
        if (state.view !== action.view || action.height !== state.height) {
          const height = action.height ? action.height : state.height;
          draft.collapsed = action.collapsed;
          draft.view = action.view;
          draft.height = height;
        }
        break;
      case CHANGE_OPEN_KEYS:
        draft.openKeys = action.openKeys;
        break;
      case CHANGE_CURRENT:
        draft.current = action.current;
        break;
      case CLEAR_MENU:
        draft.openKeys = [];
        draft.current = [];
        break;
      default:
        return state;
    }
  });

export default adminAppReducer;
