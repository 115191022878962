import produce from 'immer';

import {
  CREATE_BOOKING_AND_PAYMENT,
  CREATE_BOOKING_AND_PAYMENT_SUCCESS,
  CREATE_BOOKING_AND_PAYMENT_FAILURE,
  CLEAR_PAYMENT_INFO,
  NOT_DONE,
  SUCCESS,
  FAILED,
} from '../constants/payment';

const initialState = {
  loading: false,
  outcome: NOT_DONE,
};

const paymentReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CREATE_BOOKING_AND_PAYMENT:
        draft.loading = true;
        draft.outcome = NOT_DONE;
        draft.error = null;
        break;
      case CREATE_BOOKING_AND_PAYMENT_SUCCESS:
        draft.loading = false;
        draft.outcome = SUCCESS;
        draft.error = null;
        break;
      case CREATE_BOOKING_AND_PAYMENT_FAILURE:
        draft.loading = false;
        draft.outcome = FAILED;
        draft.error = action.error;
        break;
      case CLEAR_PAYMENT_INFO:
        draft.loading = false;
        draft.outcome = NOT_DONE;
        draft.error = null;
        break;
      default:
        return state;
    }
  });
};

export default paymentReducer;
