import * as constants from '../constants/adminMessages';
import MessageService from '../../services/MessageService';

const messageService = new MessageService();

export const getTemplates = (accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_TEMPLATES,
      });
      const response = await messageService.getTemplates(accessToken);
      const templates = {};
      const messageTypes = new Set();
      
      // determine all the possible languages. it is necessary that all languages 
      // are known in advance so a placeholder template can be created if the
      // messageType does not exist for a given language. the goal of this is so
      // a placeholder template can be edited to create the template for that language,
      // and it maintains the same form as the other messageTypes (e.g. it must have
      // the same keys and attachment types)
      response.data.forEach(template => {
        if (templates[template.language] === undefined){
          templates[template.language] = {};
        }
      })
      
      // group each template by its language
      response.data.forEach(template => {
        // track if a new message type is found. if so, add a placeholder template for all languages
        // it must only add placeholders on the first encounter so that it doesn't overwrite real templates
        if (!messageTypes.has(template.messageType)){
          messageTypes.add(template.messageType);
          for (let lang in templates){
            templates[lang][template.messageType] = {
              attachInvoice: template.attachInvoice,
              keys: template.keys,
            }
          }
        }
        // now add the new template to the groupings
        templates[template.language][template.messageType] = template;
      });

      dispatch({
        type: constants.GET_TEMPLATES_SUCCESS,
        templates: templates,
        messageTypes: messageTypes,
        languages: Object.keys(templates)
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: constants.GET_TEMPLATES_ERROR,
        payload: error,
      });
    }
  };
};

export const getTemplate = (messageType, language, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_TEMPLATE,
      });
      const response = await messageService.getTemplate(messageType, language, accessToken);
      dispatch({
        type: constants.GET_TEMPLATE_SUCCESS,
        template: response.data,
      });
    } catch (err) {
      dispatch({
        type: constants.GET_TEMPLATE_ERROR,
        payload: err,
      });
    }
  };
};

export const updateTemplate = (messageType, language, updatedData, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.UPDATE_TEMPLATE,
      });
      // eslint-disable-next-line unused-imports/no-unused-vars
      const response = await messageService.updateTemplate(messageType, language, updatedData, accessToken);
      dispatch({
        type: constants.UPDATE_TEMPLATE_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: constants.UPDATE_TEMPLATE_ERROR,
        payload: err,
      });
    }
  };
};
