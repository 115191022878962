const DOCUMENT = 'OPS_';

export const UPDATE_USER_PROFILE = DOCUMENT + 'UPDATE_USER_PROFILE';
export const SET_LOADING = DOCUMENT + 'SET_LOADING';
export const CLEAR_USER_INFO = DOCUMENT + 'CLEAR_USER_INFO';

export const CREATE_USER = DOCUMENT + 'CREATE_USER';
export const CREATE_USER_ERROR = DOCUMENT + 'CREATE_USER_ERROR';
export const CREATE_USER_SUCCESS = DOCUMENT + 'CREATE_USER_SUCCESS';

export const GET_OPS = DOCUMENT + 'GET_OPS';
export const GET_OPS_SUCCESS = DOCUMENT + 'GET_OPS_SUCCESS';
export const GET_OPS_ERROR = DOCUMENT + 'GET_OPS_ERROR';
