import TipService from '../../services/TipService';
import * as actions from '../constants/tip';

const tipService = new TipService();

/**
 * Updates the tip data inside the tipReducer
 * @param {*} tipData the new data that will update reducer state
 */
export const updateTipData = (tipData) => {
    return async(dispatch) => {
        await dispatch({
            type: actions.UPDATE_TIP,
            tipData,
        });
    }
}

export const getTip = (tipId, accessToken) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    return async(dispatch, getState) => {
        try {
            await dispatch({
                type: actions.GET_TIP,
            });
            const res = await tipService.fetchTip(tipId, accessToken);
            await dispatch({
                tipData: res,
                type: actions.GET_TIP_SUCCESS,
            }) 
        } catch(error) {
            await dispatch({
                type: actions.TIP_ERROR,
                error
            })
            throw error;
        }
    }
}

export const clearTipLoad = () => {
    return (dispatch) => {
        dispatch({
            type: actions.CLEAR_TIP,
        });
    };
}

export const updateTipDatabase = (tipId, accessToken) => {
    return async(dispatch, getState) => {
        const { tipReducer } = getState();
        const { value } = tipReducer;
        try {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const res = await tipService.editTip(tipId,{value: value.replace(',', '.')}, accessToken);
            await dispatch({
                type: actions.CLEAR_TIP,
            })
        } catch (error) {
            await dispatch({
                type: actions.TIP_ERROR,
                error
            })
            throw error;
        }
    };
}