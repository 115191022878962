import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 80%;
  text-align: center;
`;

export const Message = styled.h1`
  color: #5da1b0;
`;

export const ButtonsWrapper = styled.div`
  width: 50%;

  @media (max-width: 738px) {
    width: 100%;
  }
`;
