import api from '../../api';
import axios from 'axios';
export default class TaskService {
  /**
   * 
   * @returns all tasks
   */
  async getTasks(page, perPage = 15, accessToken) {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/tasks/?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  }

  /**
    * Get Assigned task by onfleet to send messages
    * @param {array} selectedTasks
    * @param {string} accessToken
    */
  async estimateArrivalTasks(accessToken, selectedTasks) {
    const response = await api.post(
      `/tasks/estimate-arrive`,
      selectedTasks,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }
  /**
    * Get Assigned task by onfleet to send messages
    * @param {string} accessToken
    */
  async getOnfleetTasks(accessToken) {
    const response = await api.get(
      `/tasks/onfleet`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  /**
  * Allow Ops Team to view a task with the given ID.
  * @param {string} taskID
  * @param {string} accessToken
  */
  async getTaskById(taskID, accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tasks/${taskID}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
     * @param {string} csvText the csv file as string
     * @param {Function} cb call back with the success message
     * @returns call back
     */
  async sendTasksByCsv(csvText, cb, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/tasks/upload`,
      csvText,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    ).then(cb)
  }
  async sendSMSToTasks(accessToken, tasks) {
    const messages = tasks.map(task => ({
      "message_type": task.size || "",
      "partner_name": task.partner_name || "",
      "customer_name": task.recipients ? task.recipients.map(({ name }) => name).join(',') : "",
      "phone_number": task.recipients ? task.recipients.map(({ phone }) => phone).join(',') : "",
      "delivery": {
        "taskID": task.shortId || "",
        "address": !!task.destination && !!task.destination.address ? task.destination.address : {},
        "notes": task.notes || "",
        "mate_name": !!task.delivery && !!task.delivery.mate_name ? task.delivery.mate_name : "",
        "date": task.completeAfter ? task.completeAfter.split(' ')[0] : '',
        "time_range": task.completeAfter ? task.completeAfter.split(' ')[1] : '',
      }
    }))
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/sms/send`,
      { data: messages },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    ).then(response => response.data)
  }

  async getContacts(filters, accessToken) {

    let query = '';
    for (const [key, value] of Object.entries(filters)){
      query += `&${key}=${(value)}`;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/sms/contacts?${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async sendCustomSMS(contactList, msg, accessToken) {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/sms/send/customSMS`,
      {
        contacts: contactList,
        message: msg
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return response;
  }

}
