/**
 * Gets the list of values of a location
 * @param {*} category string OneOf['pickup', 'dropoff']
 * @param {*} accessibility {0-2} indicating 0 No stairs or elevators -> RC
                                                            1 Has Stairs
                                                            2 Has an Elevator
 * @param {*} hasParking {0-1} indicating 0 location DO NOT have parking
                                                           1 location has parking
 * @param {*} floors Total number of floors on location
 * @returns the list of value of a location
 */
export function getLocationDetails(
  category,
  aptUnit,
  accessibility,
  hasParking,
  floors
) {
  return {
    category,
    aptUnit,
    accessibility,
    parking: hasParking ? 1 : 0,
    floors: floors || 0,
  };
}

/**
 * Returns the correct constant depending on the edit mode
 * @param {boolean} editMode determines if we're on edit mode
 * @param {*} originalConstant is the original constant when not on edit mode
 * @param {*} editConstant is the constant to be used when on edit mode
 * @returns the correct constant depending on the edit mode
 */
export function getCorrectConstant(editMode, originalConstant, editConstant) {
  return !editMode ? originalConstant : editConstant;
}
