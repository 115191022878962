import axios from 'axios';
import { trimObjectStrings } from '../../utils/stringSanitize';

export default class BookingService {
  /**
   * Gets the user information
   * @param {string} userId The userId encrypted in base64
   * @param {string} accessToken The access token used for the authorization header in the request
   */
  async fetchUserProfile(accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/profiles/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((resp) => resp.data);
  }

  /**
   * @param {string} userId The userId encrypted in base64
   * @param {object} profileData The fields and corresponding values to update in the user's profile
   * @param {string} accessToken The access token used for the authorization header in the request
   */
  async updateProfile(profileData, accessToken) {

    const profileDataTrimmed = trimObjectStrings(profileData);

    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/`,
      profileDataTrimmed,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return res.data;
  }
}
