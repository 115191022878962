import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${(props) =>
    props.secondary ? 'grey' : ' var(--primary-color)'};

  background-color: ${(props) => {
    switch (props.variant) {
      case 'tertiary':
        return 'var(--tertiary-color)';
      case 'secondary':
        return 'var(--secondary-color)';
      default:
        return 'var(--primary-color)';
    }
  }};
  color: #fff;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border: none;
  padding: 0.8rem;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 19px;
  text-align: center;
  width: 100%;
  border-radius: var(--border-radius);

  &:hover {
    filter: ${(props) => (props.disabled ? '' : 'brightness(0.85)')};
  }
`;
