import produce from 'immer';
import {
  UPDATE_BOOKING,
  SET_ESTIMATE,
  SET_DISCOUNT_CODE,
  CLEAR_BOOKING_INFO,
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_ERROR,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_ERROR,
} from '../constants/booking';
import bookingState from '../states/bookingState';

const initialState = bookingState;

const bookingReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_BOOKING:
        Object.entries(action.bookingData).forEach(([key, value]) => {
          draft[key] = value;
        });
        break;
      case SET_ESTIMATE:
        draft.estimate = action.estimate;
        break;
      case SET_DISCOUNT_CODE:
        draft.discountCode = action.discountCode;
        break;
      case CLEAR_BOOKING_INFO:
        state = initialState;
        return state;
      case GET_BOOKINGS:
        draft.bookings = [];
        draft.totalBookings = 0;
        draft.getBookingsLoading = true;
        draft.getBookingsError = null;
        draft.cancelBookingError = null;
        draft.cancelBookingLoading = false;
        draft.cancelBookingSuccess = false;
        break;
      case GET_BOOKINGS_SUCCESS:
        draft.bookings = action.bookings;
        draft.totalBookings = action.total;
        draft.getBookingsLoading = false;
        break;
      case GET_BOOKINGS_ERROR:
        draft.getBookingsLoading = false;
        draft.getBookingsError = action.payload;
        break;
      case CANCEL_BOOKING:
        draft.cancelBookingError = null;
        draft.cancelBookingLoading = false;
        draft.cancelBookingSuccess = false;
        break;
      case CANCEL_BOOKING_SUCCESS:
        draft.bookings = draft.bookings.map((b) => {
          if (b.id === action.id) {
            return {
              ...b,
              status: 'cancelled',
            };
          }
          return b;
        });
        draft.cancelBookingLoading = false;
        draft.cancelBookingSuccess = true;
        break;
      case CANCEL_BOOKING_ERROR:
        draft.cancelBookingError = action.payload;
        draft.cancelBookingLoading = false;
        break;
      case 'SET_STATE': // just for testing, should be deleted later
        return action.state;
      default:
        return state;
    }
  });
};

export default bookingReducer;
