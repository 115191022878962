import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_ERROR,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  GET_BOOKING_BY_ID,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_ERROR,
  OPS_GET_BOOKING_BY_ID,
  OPS_GET_BOOKING_BY_ID_SUCCESS,
  OPS_GET_BOOKING_BY_ID_ERROR,
  UPDATE_BOOKING_ERROR,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING,
} from '../constants/adminBooking';
import BookingService from '../../services/BookingService';

const bookingService = new BookingService();

export const getBookings = (page = 0, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BOOKINGS,
      });
      const resp = await bookingService.getBookings(page, perPage, accessToken);
      dispatch({
        type: GET_BOOKINGS_SUCCESS,
        total: resp.total,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
    }
  };
};

export const getFilterBookings = (filterDict, page, perPage, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BOOKINGS,
      });
      const resp = await bookingService.getFilterBookings(filterDict, page, perPage, accessToken);
      dispatch({
        type: GET_BOOKINGS_SUCCESS,
        total: resp.total,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
    }
  };
}

export const getSearchBookings = (value, page, perPage, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BOOKINGS,
      });
      const resp = await bookingService.getSearchBookings(value, page, perPage, accessToken);
      dispatch({
        type: GET_BOOKINGS_SUCCESS,
        total: resp.total,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
    }
  };
};

export const getSearchFilteredBookings = (value, filterDict, page, perPage, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BOOKINGS,
      });
      const resp = await bookingService.getSearchFilteredBookings(value, filterDict, page, perPage, accessToken);
      dispatch({
        type: GET_BOOKINGS_SUCCESS,
        total: resp.total,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
    }
  };
}

export const updateBooking = (bookingId, booking, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_BOOKING,
      });
      const resp = await bookingService.updateBooking(
        bookingId,
        booking,
        accessToken
      );
      dispatch({
        type: UPDATE_BOOKING_SUCCESS,
        booking: resp,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_BOOKING_ERROR,
        payload: error,
      });
    }
  };
};

export const getBookingById = (bookingId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BOOKING_BY_ID,
      });
      const resp = await bookingService.getBookingById(bookingId, accessToken);
      dispatch({
        type: GET_BOOKING_BY_ID_SUCCESS,
        booking: resp,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_BOOKING_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};

export const opsGetBookingById = (bookingId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OPS_GET_BOOKING_BY_ID,
      });
      const resp = await bookingService.opsGetBookingById(bookingId, accessToken);
      dispatch({
        type: OPS_GET_BOOKING_BY_ID_SUCCESS,
        booking: resp,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: OPS_GET_BOOKING_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};

export const deleteBooking = (id, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_BOOKING,
      });
      await bookingService.deleteBooking(id, accessToken);
      dispatch({
        type: DELETE_BOOKING_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_BOOKING_ERROR,
        payload: error,
      });
    }
  };
};

/**
 * Updates the status for the booking
 * @param {*} bookingData the necessary information required to complete the formula
 *                    id {string} the id of the booking to be edited
 *                    status {string} the new status for the booking
 * @param {string} accessToken The current user access token to ping the backend
 * @returns the changed status
 */
export const updateBookingStatus = ({ id, status }, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_STATUS,
      });
      let body = { id, status };
      await bookingService.updateBookingStatus(body, accessToken);
      dispatch({
        type: UPDATE_STATUS_SUCCESS,
        id,
        status,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_STATUS_ERROR,
        payload: error,
      });
    }
  };
};
