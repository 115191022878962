import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useIntl } from 'react-intl';
import { progressSelector } from 'sharedlibs/lib/redux/selectors/booking';

import {
  Step,
  StepperWrapper,
  DividerLine,
  PopOverCloseTextWrapper,
  PopOverCloseText,
} from './styles';

const Stepper = ({
  editMode,
  onStepSelect,
  viewStepperMessage,
  setViewStepperMessage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const buttonStates = useSelector(({ bookingReducer }) =>
    !editMode
      ? progressSelector(bookingReducer)
      : ['current', 'current', 'current']
  );

  const goToStep = (index, progress) => {
    if (!editMode) {
      const paths = {
        1: '/locations',
        2: '/move-type',
        3: '/schedule',
      };
      if (progress === 'complete' || progress === 'current') {
        history.push(paths[index]);
      }
    } else {
      onStepSelect(index);
    }
  };

  const renderCompleteStep = () => {
    const status = buttonStates.every((progress) => progress === 'complete')
      ? 'finished'
      : 'incomplete';
    return (
      <Step
        progress={!editMode ? status : 'finished'}
        onClick={() => {
          if (!editMode) {
            if (status === 'finished') {
              history.push('/quote');
            }
          } else {
            onStepSelect(4);
          }
        }}
      >
        <CheckOutlined className="check" />
      </Step>
    );
  };

  const renderSteps = () => {
    return (
      <StepperWrapper>
        {buttonStates.map((progress, idx) => {
          return (
            <React.Fragment key={idx}>
              <Step
                onClick={() => goToStep(idx + 1, progress)}
                progress={progress}
              >
                {idx + 1}
              </Step>
              <DividerLine progress={progress} />
            </React.Fragment>
          );
        })}
        {renderCompleteStep()}
      </StepperWrapper>
    );
  };

  const renderSteppers = () => {
    if (!editMode) {
      return renderSteps();
    }
    return (
      <Popover
        title={intl.formatMessage({ id: 'description.bookingEditModeMessage' })}
        content={
          <PopOverCloseTextWrapper>
            <PopOverCloseText onClick={() => setViewStepperMessage(false)}>
              Close
            </PopOverCloseText>
          </PopOverCloseTextWrapper>
        }
        visible={viewStepperMessage}
        placement="bottom"
        trigger="click"
      >
        {renderSteps()}
      </Popover>
    );
  };

  return renderSteppers();
};

Stepper.defaultProps = {
  editMode: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStepSelect: () => {},
};

Stepper.propTypes = {
  editMode: PropTypes.bool,
  onStepSelect: PropTypes.func,
  viewStepperMessage: PropTypes.bool,
  setViewStepperMessage: PropTypes.func,
};

export default Stepper;
