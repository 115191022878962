import axios from 'axios';

export default class TipService {
    fetchTip(id, accessToken) {
        return axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/c/tip/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((resp) => resp.data)
    }

    editTip(id, tipData, accessToken) {
        return axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/tip/${id}`,
                tipData,
                {
                    headers: { Authorization: `Bearer ${accessToken}`},
                }
            )
            .then((resp) => resp.data);
    } 

    fetchOpsTip(id, accessToken) {
        return axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/tip/${id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}`},
                }
            )
            .then((resp) => resp.data);
    }
}