import styled from 'styled-components';

export const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media (max-width: 738px) {
    justify-content: center;
    margin: 0;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  height: 2.5em;
  width: 2.5em;
  border-radius: 10000px;
  font-weight: 500;
  color: var(--tertiary-color);
  color: ${(props) => {
    if (props.progress === 'current') {
      return ' var(--secondary-color)';
    } else if (['complete', 'finished'].includes(props.progress)) {
      return '#fff';
    }
    return ' var(--tertiary-color)';
  }};
  border: 0.2em solid
    ${(props) => {
      if (['current', 'complete', 'finished'].includes(props.progress)) {
        return ' var(--secondary-color)';
      }
      return '#fff';
    }};
  // change background color based on state
  background-color: ${(props) => {
    if (props.progress === 'current') {
      return '#fff';
    } else if (['complete', 'finished'].includes(props.progress)) {
      return ' var(--secondary-color)';
    }
    return 'var(--light-gray)';
  }};
  cursor: ${(props) => {
    if (['current', 'complete', 'finished'].includes(props.progress)) {
      return 'pointer';
    }
    return 'default';
  }};

  & .check {
    ${(props) => props.progress === 'finished' && 'font-size: 1.8em;'}
  }

  @media (max-width: 738px) {
    height: 1.8em;
    width: 1.8em;
    font-size: 1em;
  }

  &:hover {
    transform: ${(props) => {
      if (['current', 'complete', 'finished'].includes(props.progress)) {
        return 'scale(1.1)';
      }
      return 'none';
    }};
`;

export const DividerLine = styled.div`
  align-self: center;
  height: 3px;
  width: 8%;
  background-color: ${(props) => {
    if (props.progress === 'complete') {
      return ' var(--secondary-color)';
    }
    return '#fff';
  }};

  @media (max-width: 738px) {
    height: 2px;
  }
`;

export const PopOverCloseText = styled.span`
  color: red;
  cursor: pointer;
`;

export const PopOverCloseTextWrapper = styled.div`
  color: red;
  text-align: right;
`;
