import produce from 'immer';
import { CHANGE_THEME } from '../constants/adminTheme';

const initialState = {
  changeThemes: 'defaultTheme',
  topbarTheme: 'defaultTheme',
  sidebarTheme: 'defaultTheme',
  layoutTheme: 'defaultTheme',
};

const adminThemeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_THEME:
        draft[action.attr] = action.theme;
        break;
      default:
        return state;
    }
  });

export default adminThemeReducer;
