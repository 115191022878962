import {
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_ERROR,
  GET_TASK_BY_ID,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_ID_ERROR,
  UPDATE_TASK_ERROR,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK,
  GET_INVALID_TASKS,
  GET_INVALID_TASKS_SUCCESS,
  GET_INVALID_TASKS_ERROR,
  GET_INVALID_TASK_BY_ID_SUCCESS,
  GET_INVALID_TASK_BY_ID_ERROR,
  GET_INVALID_TASK_BY_ID
} from '../constants/adminB2BBooking';
import B2BBookingService from '../../services/B2BBookingService';

const b2bTaskService = new B2BBookingService();

export const getAllB2BTasks = (page = 0, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TASKS,
      });
      // eslint-disable-next-line no-undef
      const resp = await b2bbookingService.getTasks(page, perPage, accessToken);
      dispatch({
        type: GET_TASKS_SUCCESS,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_TASKS_ERROR,
        payload: error,
      });
    }
  };
};

export const getTaskById = (taskID, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TASK_BY_ID,
      });
      const resp = await b2bTaskService.getTaskById(taskID, accessToken);
      dispatch({
        type: GET_TASK_BY_ID_SUCCESS,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_TASK_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};

export const updateTaskById = ( taskID, updatedTask, accessToken) =>  {
  return async (dispatch) =>  {
    try {
      dispatch({
        type: UPDATE_TASK,
      });
      const resp = await b2bTaskService.updateTaskById(taskID, updatedTask, accessToken);
      dispatch({
        type: UPDATE_TASK_SUCCESS,
        data: resp,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_TASK_ERROR,
        payload: error,
      });
    }
  }
}

export const getAllB2BInvalidTasks = (page = 0, perPage = 10, accessToken) => {
  
  return async (dispatch) => {    
    try {
      dispatch({
        type: GET_INVALID_TASKS,
      });
      const resp = await b2bTaskService.getAllInvalidTasks(page, perPage, accessToken);
      dispatch({
        type: GET_INVALID_TASKS_SUCCESS,
        data: resp.data,
      });
    } catch (error) {
      dispatch({
        type: GET_INVALID_TASKS_ERROR,
        payload: error,
      });
      
    }
  };
};

export const getB2BInvalidTask = (taskID, accessToken) => {
  return async (dispatch) =>  {
      try {
          dispatch({
              type: GET_INVALID_TASK_BY_ID,
          });
          const resp = await b2bTaskService.getInvalidTask(taskID, accessToken);
          dispatch({
              type: GET_INVALID_TASK_BY_ID_SUCCESS,
              total: resp.total,
              data: resp.data,
          });
      } catch (error) {
          console.error(error);
          dispatch({
            type: GET_INVALID_TASK_BY_ID_ERROR,
            payload: error,
          });
      }
  }
}

export const getFilteredTasks = (filterDict, page, perPage, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TASKS,
      });
      const resp = await b2bTaskService.getFilteredTasks(filterDict, page, perPage, accessToken);
      dispatch({
        type: GET_TASKS_SUCCESS,
        total: resp.total,
        data: resp.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_TASKS_ERROR,
        payload: error,
      });
    }
  };
}





