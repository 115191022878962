import produce from 'immer';
import {
  TEMP_GET_ROUTE_DATA,
  TEMP_GET_ROUTE_DATA_SUCCESS,
  TEMP_GET_ROUTE_DATA_ERROR,
  TEMP_CLEAR_MAPBOX_INFO,
  TEMP_SET_ROUTE_DATA,
  TEMP_SET_ROUTE_DATA_SUCCESS,
} from '../constants/tempMapbox';
import mapboxState from '../states/mapboxState';

const initialState = mapboxState;

const tempMapboxReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TEMP_GET_ROUTE_DATA:
        draft.loading = true;
        break;
      case TEMP_GET_ROUTE_DATA_SUCCESS:
        draft.loading = false;
        draft.route = action.payload.route;
        draft.duration = action.payload.duration;
        draft.distance = action.payload.distance;
        draft.pickup = action.payload.pickup;
        draft.dropoff = action.payload.dropoff;
        break;
      case TEMP_GET_ROUTE_DATA_ERROR:
        draft.loading = false;
        break;
      case TEMP_SET_ROUTE_DATA:
        draft.duration = 0;
        draft.distance = 0;
        break;
      case TEMP_SET_ROUTE_DATA_SUCCESS:
        draft.duration = action.payload.duration;
        draft.distance = action.payload.distance;
        break;
      case TEMP_CLEAR_MAPBOX_INFO:
        state = initialState;
        return state;
      default:
        return state;
    }
  });

export default tempMapboxReducer;
