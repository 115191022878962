import {
  GET_ROUTE_DATA,
  GET_ROUTE_DATA_SUCCESS,
  GET_ROUTE_DATA_ERROR,
} from '../constants/mapbox';
import {
  TEMP_GET_ROUTE_DATA,
  TEMP_GET_ROUTE_DATA_SUCCESS,
  TEMP_GET_ROUTE_DATA_ERROR,
} from '../constants/tempMapbox';
import { getCorrectConstant } from '../../utils/bookingUtil';
import MapboxService from '../../services/MapboxService';

const mapboxService = new MapboxService();

export const getRouteData = (from, to, editMode = false) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: getCorrectConstant(editMode, GET_ROUTE_DATA, TEMP_GET_ROUTE_DATA),
      });
      const res = await mapboxService.getRouteData(from, to);
      const { pickup, dropoff, route } = res;
      
      if (route.data.code === 'NoRoute' || route.data.code === 'InvalidInput') {
        dispatch({
          type: getCorrectConstant(
            editMode,
            GET_ROUTE_DATA_ERROR,
            TEMP_GET_ROUTE_DATA_ERROR
          ),
          // eslint-disable-next-line no-undef
          payload: error,
        });
      } else {
        dispatch({
          type: getCorrectConstant(
            editMode,
            GET_ROUTE_DATA_SUCCESS,
            TEMP_GET_ROUTE_DATA_SUCCESS
          ),
          payload: {
            route: route.data.routes[0].geometry.coordinates,
            distance: route.data.routes[0].distance,
            duration: route.data.routes[0].duration,
            pickup,
            dropoff,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: getCorrectConstant(
          editMode,
          GET_ROUTE_DATA_ERROR,
          TEMP_GET_ROUTE_DATA_ERROR
        ),
        payload: error,
      });
    }
  };
};

export const getRouteDataForward = (address) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  return async (dispatch) => {
    const PROXIMITY_GPS = '-73.646116,45.498647'; // GPS coord of middle of Montreal Island
    // const MONTREAL_BBOX = [-74.06136, 45.2429815, -73.344845, 45.7763585];
    const CANADA_BBOX = [-140.99778, 41.6751050889, -52.6480987209, 83.23324];

    try {
      const resp = await mapboxService.forwardGeocodeSuggestions(
        address,
        'address',
        'CA',
        PROXIMITY_GPS,
        CANADA_BBOX 
      );

      return handleGetLocationItem(resp.data.features[0]);
    } catch (err) {
      console.log(err);
    }
  };
};

const handleGetLocationItem = (location) => {
  const coordinates = {
    lng: location.center[0],
    lat: location.center[1],
  };

  // extract location components from location object
  const locationComponents = {
    country: '',
    province: '',
    postalCode: '',
    city: '',
    street: '',
    unit: '',
    fullAddress: '',
  };
  for (let locationComponent of location.context) {
    if (/\b(country).\b/.test(locationComponent.id)) {
      locationComponents.country = locationComponent.text || '';
    } else if (/\b(region).\b/.test(locationComponent.id)) {
      locationComponents.province = locationComponent.text || '';
    } else if (/\b(postcode).\b/.test(locationComponent.id)) {
      locationComponents.postalCode = locationComponent.text || '';
    } else if (/\b(place).\b/.test(locationComponent.id)) {
      locationComponents.city = locationComponent.text || '';
    }
  }
  locationComponents.unit = location.address || '';
  locationComponents.street = location.text || '';
  locationComponents.fullAddress = location.place_name || '';

  return {
    coordinates,
    location: locationComponents,
  };
};
