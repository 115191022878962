import {
  CREATE_BOOKING_AND_PAYMENT,
  CREATE_BOOKING_AND_PAYMENT_SUCCESS,
  CREATE_BOOKING_AND_PAYMENT_FAILURE,
  CLEAR_PAYMENT_INFO,
} from '../constants/payment';

import { updateBookingData, createBooking, clearStore } from '../actions';

import BraintreeService from '../../api/braintree';

const braintreeService = new BraintreeService();

/**
 *
 * @param {string} paymentNonce
 * @param {object} customerData
 * @param {string} resourceId
 * @param {string} start
 * @param {string} end
 */
export const createBookingAndPayment = (
  paymentNonce,
  customerData,
  start,
  end,
  userId,
  accessToken
) => {
  return async (dispatch, getState) => {
    try {
      const { bookingReducer, tipReducer } = getState();
      const bookingData = {
        bookingType: bookingReducer.bookingType,
        pickup: bookingReducer.pickup,
        pickupAptUnit: bookingReducer.pickupAptUnit,
        pickupDetails: bookingReducer.pickupDetails,
        pickupFloor: bookingReducer.pickupFloor,
        pickupHasElevator: bookingReducer.pickupHasElevator,
        dropoff: bookingReducer.dropoff,
        dropoffAptUnit: bookingReducer.dropoffAptUnit,
        dropoffFloor: bookingReducer.dropoffFloor,
        dropoffHasElevator: bookingReducer.dropoffHasElevator,
        numberOfHelpers: bookingReducer.numberOfHelpers,
        justMates: bookingReducer.justMates,
        firstMoverRate: bookingReducer.firstMoverRate,
        additionalMoversRate: bookingReducer.additionalMoversRate,
        rideAlong: bookingReducer.rideAlong,
        priceTaxed: bookingReducer.priceTaxed,
        discount: bookingReducer.discount,
        isPercent: bookingReducer.comments,
        braintreeCustomerId: bookingReducer.braintreeCustomerId,
        comments: bookingReducer.comments,
        date: bookingReducer.date,
        time: bookingReducer.time,
        resourceId: bookingReducer.resourceId,
        estimate: bookingReducer.estimate,
        clientAsMate: bookingReducer.clientAsMate,
        mateCount: bookingReducer.mateCount,
        items: bookingReducer.selectedFurnitures,
      };
      // eslint-disable-next-line unused-imports/no-unused-vars
      const tipValue = tipReducer.value;
  
      // start booking and payment request
      dispatch({ type: CREATE_BOOKING_AND_PAYMENT });

      // create customer and get braintree id
      const braintreeRes = await braintreeService.createBraintreeCustomer(
        {
          ...customerData,
          userId,
          paymentNonce,
        },
        accessToken
      );
      const customerId = braintreeRes.customerId;

      await dispatch(updateBookingData('braintreeCustomerId', customerId));

      // create booking in Movemate server
      await dispatch(createBooking(userId, accessToken));

      window.Intercom('update', 
        {
          hasBooked: true,
          bookingType: bookingData.bookingType,
          moveDate_at: Math.floor(new Date(bookingData.time).getTime() / 1000),
          price: bookingData.estimate.amount,
        }
      );

      dispatch({ type: CREATE_BOOKING_AND_PAYMENT_SUCCESS });
      dispatch(clearStore());
    } catch (error) {
      // dispatch failed fetch action
      console.error(error);
      dispatch({ type: CREATE_BOOKING_AND_PAYMENT_FAILURE, error: error });
    }
  };
};

export const clearPaymentLoad = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PAYMENT_INFO });
  };
};
