import enMesssages from './translations/en.json';
import frMesssages from './translations/fr.json';
import zhMessages from './translations/zh.json'

const appLocales = ['en', 'fr', 'zh'];
const messages = {
  en: enMesssages,
  fr: frMesssages,
  zh: zhMessages,
};

export { messages, appLocales };
