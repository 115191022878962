const options = [
  {
    key: 'my-profile',
    label: 'sidebar.profile',
    leftIcon: 'ion-person',
  },
  {
    key: 'pages',
    label: 'sidebar.pages',
    leftIcon: 'ion-document-text',
    children: [
      {
        key: '404',
        label: 'sidebar.404',
        withoutDashboard: true,
      },
      {
        key: '500',
        label: 'sidebar.500',
        withoutDashboard: true,
      },
      {
        key: 'signin',
        label: 'sidebar.signIn',
        withoutDashboard: true,
      },
      {
        key: 'signup',
        label: 'sidebar.signUp',
        withoutDashboard: true,
      },
      {
        key: 'forgotpassword',
        label: 'sidebar.forgotPw',
        withoutDashboard: true,
      },
      {
        key: 'resetpassword',
        label: 'sidebar.resetPw',
        withoutDashboard: true,
      },
    ],
  },
];

export function getDefaultPath() {
  const getParent = (lastRoute) => {
    const parent = [];
    if (!lastRoute) return parent;
    parent.push(lastRoute);
    options.forEach((option) => {
      if (option.children) {
        option.children.forEach((child) => {
          if (child.key === lastRoute) {
            parent.push(option.key);
          }
        });
      }
    });
    return parent;
  };
  if (!isServer && window.location.pathname) {
    const routes = window.location.pathname.split('/');
    if (routes.length > 1) {
      const lastRoute = routes[routes.length - 1];
      return getParent(lastRoute);
    }
  }
  return [];
}

export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

export const isServer = typeof window === 'undefined';
