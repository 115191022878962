import axios from 'axios';

export default class BraintreeService {
  /**
   * constructor for BraintreeService
   */
  constructor() {
    // set the token based on dev or prod environment
    this.token = process.env.REACT_APP_BRAINTREE_TOKEN;
  }

  /**
   * return braintree token
   * @returns {String} braintree token string
   */
  async getClientToken(accessToken) {
    // send post request to backend
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/client`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return res.data;
    } catch (error) {
      // if Braintree customer token isn't found using wallet in our database,
      // return the generic API token key as fallback
      return process.env.REACT_APP_BRAINTREE_TOKEN;
    }
  }

  /**
   * send customerData to backend to create new customer in braintree
   * @param {Object} customerData
   * @param {string} accessToken
   */
  async createBraintreeCustomer(customerData, accessToken) {
    // tells backend if we are in dev or test environment or not
    customerData.sandbox = this.sandbox;

    // send post request to backend
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment`,
        customerData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return res.data;
    } catch (error) {
      console.error(error.response.data);
      // throw error to Payment redux action > PaymentPage
      throw 'Payment: ' + error.response.data;
    }
  }

  /**
   * send customerData to backend to create new customer in braintree
   * @param {Object} customerData
   * @param {string} accessToken
   */
  async createBraintreePaymentMethod(customerData, accessToken) {
    // tells backend if we are in dev or test environment or not
    customerData.sandbox = this.sandbox;

    // send post request to backend
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/card`,
        customerData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return res.data;
    } catch (error) {
      console.error(error.response.data);
      // throw error
      throw 'PaymentCard: ' + error.response.data;
    }
  }

  /**
   * send customerData to backend to update customer's default payment in braintree
   * @param {Object} customerData
   * @param {string} accessToken
   */
  async updateBraintreePayment(customerData, accessToken) {
    // send post request to backend
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment`,
        customerData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
}
