import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  SET_LOADING,
  CLEAR_USER_ERROR,
} from "../constants/user";
import UserService from "../../services/UserService";

const userService = new UserService();

export const getUserProfile = (accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_USER_ERROR,
    });
    dispatch({
      type: SET_LOADING,
      loading: true,
    });
    try {
      const resp = await userService.fetchUserProfile(
        accessToken
      );
      if (resp) {
        const { billingAddress, firstName, lastName, phone, email } = resp;
        dispatch({
          type: GET_USER_PROFILE,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email,
          billingAddress: billingAddress || {
            unit: null,
            streetName: null,
            aptUnit: "",
            city: null,
            postalCode: null,
            province: null,
            country: "Canada",
          },
        });
        dispatch({
          type: SET_LOADING,
          loading: false,
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: GET_USER_PROFILE_ERROR,
        payload: err,
      });
    }
  };
};

export const updateUserProfile = async (profileData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_USER_ERROR,
    });
    try {
      await userService.updateProfile(
        profileData,
        accessToken
      );
      dispatch({
        type: UPDATE_USER_PROFILE,
        profileData,
      });
    }
     catch (err) {
      console.error(err);
      dispatch({
        type: UPDATE_USER_PROFILE_ERROR,
        payload: err,
      });
    }
  };
};
