import moment from 'moment';
import {
  UPDATE_BOOKING,
  SET_ESTIMATE,
  SET_DISCOUNT_CODE,
  CLEAR_BOOKING_INFO,
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_ERROR,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_ERROR,
} from '../constants/booking';
import {
  TEMP_UPDATE_BOOKING,
  TEMP_SET_ESTIMATE,
  TEMP_SET_DISCOUNT_CODE,
  UPDATE_TEMP_EDIT_BOOKING_SUCCESS,
} from '../constants/tempBooking';
import { CLEAR_USER_INFO } from '../constants/user';
import { CLEAR_MAPBOX_INFO } from '../constants/mapbox';
import { CLEAR_PAYMENT_INFO } from '../constants/payment';
import { CLEAR_TIP } from '../constants/tip';
import { CLEAR_QUERY_INFO } from '../constants/query';
import BookingService from '../../services/BookingService';
import {
  getLocationDetails,
  getCorrectConstant,
} from '../../utils/bookingUtil';

const bookingService = new BookingService();

/**
 * Updates the data inside the correct booking reducer
 * @param {*} bookingData the data that will be updated
 * @param {boolean} editMode determines if we're updating on the booking reducer or temp booking reducer
 */
export const updateBookingData = (bookingData, editMode = false) => {
  return async (dispatch) => {
    await dispatch({
      type: getCorrectConstant(editMode, UPDATE_BOOKING, TEMP_UPDATE_BOOKING),
      bookingData,
    });
    dispatch(getEstimate(editMode));
  };
};

/**
 * Gets a discount response from the backend
 */
export const getDiscount = (discountCode) => {
  return async (_, getState) => {
    const { bookingReducer } = getState();
    try {
      const { date } = bookingReducer;
      const resp = await bookingService.fetchDiscount(
        discountCode,
        moment(date).toISOString()
      );
      return resp;
    } catch (error) {
      return null;
    }
  };
};

/**
 * Gets the estimate value for the current or editing booking
 * @param {boolean} editMode determines if we're updating on the booking reducer or temp booking reducer
 */
export const getEstimate = (editMode) => {
  return async (dispatch, getState) => {
    const {
      bookingReducer,
      tempBookingReducer,
      mapboxReducer,
      tempMapboxReducer,
      geoReducer
    } = getState();
    const {
      clientAsMate,
      date,
      discountCode,
      dropoffFloor,
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      justMates,
      mateCount,
      pickup,
      pickupFloor,
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      rideAlong,
      selectedFurnitures,
    } = !editMode ? bookingReducer : tempBookingReducer;
    const { duration } = !editMode ? mapboxReducer : tempMapboxReducer;
    const { tax } = geoReducer;

    const pickupLocationDetails = getLocationDetails(
      'pickup',
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      pickupFloor
    );
    const dropoffLocationDetails = getLocationDetails(
      'dropoff',
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      dropoffFloor
    );
    const bookingData = {
      travelDuration: duration / 60 || 1,
      selectedFurnitures: { furnitures: selectedFurnitures },
      mateCount: mateCount || 1,
      locationDetails: [pickupLocationDetails, dropoffLocationDetails],
      rideAlong,
      movingDate: moment(date).toISOString() || moment().toISOString(),
      discountCode,
      clientAsMate,
      justMates,
      tax: tax.rate,
      pickupAddress: pickup,
    };

    try {
      const estimate = await bookingService.fetchEstimate(bookingData);
      if (estimate && !estimate.discountCode) {
        dispatch({
          type: getCorrectConstant(
            editMode,
            SET_DISCOUNT_CODE,
            TEMP_SET_DISCOUNT_CODE
          ),
          discountCode: null,
        });
      }
      dispatch({
        type: getCorrectConstant(editMode, SET_ESTIMATE, TEMP_SET_ESTIMATE),
        estimate,
      });
    } catch (error) {
      dispatch({
        type: getCorrectConstant(editMode, SET_ESTIMATE, TEMP_SET_ESTIMATE),
        estimate: {
          amount: 0,
          currency: 'CAD',
          tax: 0,
        },
      });
    }
  };
};

/**
 * Gets the estimated duration for the current booking
 * @param {boolean} editMode determines if we're updating on the booking reducer or temp booking reducer
 */
export const getDuration = (editMode = false) => {
  return async (dispatch, getState) => {
    const {
      bookingReducer,
      tempBookingReducer,
      mapboxReducer,
      tempMapboxReducer,
    } = getState();
    const {
      clientAsMate,
      date,
      discountCode,
      dropoffFloor,
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      justMates,
      mateCount,
      pickupFloor,
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      rideAlong,
      selectedFurnitures,
    } = !editMode ? bookingReducer : tempBookingReducer;
    const { duration } = !editMode ? mapboxReducer : tempMapboxReducer;

    const pickupLocationDetails = getLocationDetails(
      'pickup',
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      pickupFloor
    );
    const dropoffLocationDetails = getLocationDetails(
      'dropoff',
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      dropoffFloor
    );

    const bookingData = {
      travelDuration: duration / 60 || 1,
      selectedFurnitures: { furnitures: selectedFurnitures },
      mateCount: mateCount || 1,
      locationDetails: [pickupLocationDetails, dropoffLocationDetails],
      rideAlong,
      movingDate: moment(date).toISOString() || moment().toISOString(),
      discountCode,
      clientAsMate,
      justMates,
    };

    try {
      const moveDuration = await bookingService.fetchDuration(bookingData);
      dispatch(
        updateBookingData({ estimatedDuration: moveDuration.duration, isDurationWithinLimit: moveDuration.is_duration_within_limit }, editMode)
      );

      return moveDuration || -1;
    } catch (error) {
      console.error(error);
      return -1;
    }
  };
};

/**
 *
 * @param {*} userId The current user id used for the booking, should be eliminated in the future
 * @param {*} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createBooking = (userId, accessToken) => {
  return async (dispatch, getState) => {
    try {
      const { bookingReducer, mapboxReducer, languageReducer, tipReducer, geoReducer } = getState();
      const {
        bookingType,
        clientAsMate,
        comment,
        date,
        discount,
        discountCode,
        dropoffFloor,
        dropoffAptUnit,
        dropoffHasElevator,
        dropoffHasParking,
        justMates,
        mateCount,
        pickupFloor,
        pickupAptUnit,
        pickupHasElevator,
        pickupHasParking,
        rideAlong,
        selectedFurnitures,
        time,
        resourceId,
        pickup,
        dropoff,
      } = bookingReducer;
      const { distance, duration } = mapboxReducer;
      const { tax } = geoReducer;

      const tipData = {
        value: tipReducer.value.replace(',', '.'),
        isPaid: false,
      };

      const pickupLocationDetails = getLocationDetails(
        'pickup',
        pickupAptUnit,
        pickupHasElevator,
        pickupHasParking,
        pickupFloor
      );
      const dropoffLocationDetails = getLocationDetails(
        'dropoff',
        dropoffAptUnit,
        dropoffHasElevator,
        dropoffHasParking,
        dropoffFloor
      );

      const data = {
        travelDuration: duration / 60 || 1,
        selectedFurnitures: { furnitures: selectedFurnitures },
        movingDate: moment(date).toISOString() || moment().toISOString(),
        discountCode,
        bookingType,
        clientAsMate,
        comment,
        date: moment(date).toISOString(),
        discount,
        dropoffAddress: {
          unit: dropoff.unit || '',
          street: dropoff.street || '',
          postalCode: dropoff.postalCode || '',
          city: dropoff.city || '',
          province: dropoff.province || '',
          country: dropoff.country || '',
          aptUnit: dropoffAptUnit || '',
          fullAddress: dropoff.fullAddress || '',
        },
        estimatedDistance: distance,
        justMates,
        locationDetails: [pickupLocationDetails, dropoffLocationDetails],
        mateCount,
        pickupAddress: {
          unit: pickup.unit || '',
          street: pickup.street || '',
          postalCode: pickup.postalCode || '',
          city: pickup.city || '',
          province: pickup.province || '',
          country: pickup.country || '',
          aptUnit: pickupAptUnit || '',
          fullAddress: pickup.fullAddress || '',
        },
        rideAlong,
        userId: window.btoa(userId),
        time: moment(time).toISOString(),
        language: languageReducer.locale,
        calendarId: resourceId,
        tax: tax.rate,
      };

      return await bookingService.createBooking(data, tipData, accessToken);
    } catch (error) {
      throw error;
    }
  };
};

export const fetchPreviousBookings = (page, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: GET_BOOKINGS,
      });
      await dispatch({ type: UPDATE_TEMP_EDIT_BOOKING_SUCCESS });
      const bookings = await bookingService.fetchPreviousBookings(
        page,
        perPage,
        accessToken
      );
      await dispatch({
        type: GET_BOOKINGS_SUCCESS,
        bookings: bookings.data,
        total: bookings.total,
      });
    } catch (error) {
      await dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
      return error;
    }
  };
};

export const clearStore = () => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: CLEAR_BOOKING_INFO,
      });
      await dispatch({
        type: CLEAR_MAPBOX_INFO,
      });
      await dispatch({
        type: CLEAR_USER_INFO,
      });
      await dispatch({
        type: CLEAR_PAYMENT_INFO,
      });
      await dispatch({
        type: CLEAR_TIP,
      });
      await dispatch({
        type: CLEAR_QUERY_INFO,
      })
    } catch (error) {
      return error;
    }
  };
};

// only used for testing
export const setBookingState = (state) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_STATE',
      state,
    });
  };
};

export const getBiggestFurniture = (selectedFurnitures) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  return (dispatch, getState) => {
    return selectedFurnitures.reduce((max, furniture) => {
      if (!max) {
        return furniture;
      }
      return max.cubic < furniture.cubic ? furniture : max;
    }, null);
  };
};

export const cancelBooking = (id, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_BOOKING,
      });
      await bookingService.cancelBooking(id, accessToken);
      dispatch({
        type: CANCEL_BOOKING_SUCCESS,
        id,
      });
    } catch (err) {
      dispatch({
        type: CANCEL_BOOKING_ERROR,
        payload: err,
      });
    }
  };
};
