import * as constants from '../constants/adminTask';
import TaskService from '../../services/TaskService';

const taskService = new TaskService();

export const getTasks = (page = 0, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_TASKS,
      });
      const response = await taskService.getTasks(page, perPage, accessToken);
      dispatch({
        type: constants.GET_TASKS_SUCCESS,
        tasks: response.data.tasks,
        total: response.data.total,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: constants.GET_TASKS_ERROR,
        payload: error,
      });
    }
  };
};

export const getTaskById = (taskId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_TASK_BY_ID,
      });
      const response = await taskService.getTaskById(taskId, accessToken);
      dispatch({
        type: constants.GET_TASK_BY_ID_SUCCESS,
        task: response.data,
      });
    } catch (err) {
      dispatch({
        type: constants.GET_TASK_BY_ID_ERROR,
        payload: err,
      });
    }
  };
};


export const getOnfleetTasks = (accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_ONFLEET_TASKS,
      });
      const response = await taskService.getOnfleetTasks(accessToken);
      dispatch({
        type: constants.GET_ONFLEET_TASKS_SUCCESS,
        tasks: response.data,
      });
    } catch (error) {
      dispatch({
        type: constants.GET_ONFLEET_TASKS_ERROR,
        payload: error,
      });
    }
  };
};


export const estimateArrivalTasks = (accessToken, selectedTasks) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_ARRIVAL_TASKS,
      });
      const response = await taskService.estimateArrivalTasks(accessToken, selectedTasks);
      dispatch({
        type: constants.GET_ARRIVAL_TASKS_SUCCESS,
        tasks: response.data,
      });
    } catch (error) {
      dispatch({
        type: constants.GET_ARRIVAL_TASKS_ERROR,
        payload: error,
      });
    }
  };
};

export const sendSMSToTasks = (accessToken, tasks) => {
  return async (dispatch) => {
    try {
      const response = await taskService.sendSMSToTasks(accessToken, tasks);
      dispatch({
        type: constants.SEND_SMS_TASK,
        payload: response.message,
      });
    } catch (error) {
      dispatch({
        type: constants.SEND_SMS_TASK_ERROR,
        payload: error,
      });
    }
  };
};
export function handleTaskSizeMessage(taskId = '', size = 'small') {
  return dispatch => {
    dispatch({
      type: constants.CHANGE_ONFLEET_TASK_SIZE,
      payload: { taskId, size },
    });
  }
}

export function handleTaskTimeRange(taskId = '', time = '') {
  return dispatch => {
    dispatch({
      type: constants.CHANGE_ONFLEET_TASK_TIME_RANGE,
      payload: { taskId, time },
    });
  }
}

export const getAllContacts = (filters, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.GET_CONTACTS,
      })
      const response = await taskService.getContacts(filters, accessToken)
      dispatch({
        type: constants.GET_CONTACTS_SUCCESS,
        payload: response.data.items
      })
    } catch (error) {
      dispatch({
        type: constants.GET_CONTACTS_ERROR,
        payload: error,
      })
      print(error)
    }
  }
}

export const sendCustomSMS = (contacts, message, accessToken) => {
  return async (dispatch) => {
    try {
      const response = await taskService.sendCustomSMS(contacts, message, accessToken);
      dispatch({
        type: constants.SEND_CUSTOM_SMS,
        payload: response.data
      })
    } catch (error) {
      dispatch({
        type: constants.SEND_CUSTOM_SMS_ERROR,
        payload: error
      })
    }
  }
}