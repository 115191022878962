import axios from 'axios';

export default class RateService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async createRate() {}

  async getRates(page, perPage, accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/rates?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async getRateById(rateId, accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/rates/${rateId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async updateRate(rateId, updatedRate, accessToken) {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/rates/${rateId}`,
      updatedRate,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async deleteRate(rateId, accessToken) {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/rates/${rateId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async getActiveRate(rateName) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/rates/active?name=${rateName}`
    );
    return response;
  }
}
