export const CREATE_BOOKING_AND_PAYMENT = 'CREATE_BOOKING_AND_PAYMENT';
export const CREATE_BOOKING_AND_PAYMENT_SUCCESS =
  'CREATE_BOOKING_AND_PAYMENT_SUCCESS';
export const CREATE_BOOKING_AND_PAYMENT_FAILURE =
  'CREATE_BOOKING_AND_PAYMENT_FAILURE';
export const CLEAR_PAYMENT_INFO = 'CLEAR_PAYMENT_INFO';

export const FAILED = 'FAILED';
export const SUCCESS = 'SUCCESS';
export const NOT_DONE = 'NOT_DONE';
