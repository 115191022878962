import {
  COLLAPSE_CHANGE,
  COLLAPSE_OPEN_DRAWER,
  CHANGE_OPEN_KEYS,
  TOGGLE_ALL,
  CHANGE_CURRENT,
  CLEAR_MENU,
} from '../constants/adminApp';

export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

export const toggleCollapsed = () => ({
  type: COLLAPSE_CHANGE,
});

export const toggleAll = (width, height) => {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  return {
    type: TOGGLE_ALL,
    collapsed,
    view,
    height,
  };
};

export const toggleOpenDrawer = () => ({
  type: COLLAPSE_OPEN_DRAWER,
});

export const changeOpenKeys = (openKeys) => ({
  type: CHANGE_OPEN_KEYS,
  openKeys,
});

export const changeCurrent = (current) => ({
  type: CHANGE_CURRENT,
  current,
});

export const clearMenu = () => ({
  type: CLEAR_MENU,
});
