import * as actions from '../constants/geo';
import salesTax from 'sales-tax';
import { provinceMap } from "../../utils/provinces";
import MapboxService from "../../services/MapboxService/";
import moment from 'moment-timezone';

const mapboxService = new MapboxService();

export const getGeoLocation = (coords) => {
    return async(dispatch) => {
        try {
            await dispatch({
                type: actions.FETCH_GEO_LOCATION,
            });
            const geoDataRes = await mapboxService.reverseGeocode(coords);
            const detailedAddress = mapboxService.parseReverseGeo(geoDataRes);
            const data = {
                province: detailedAddress.province, 
                proximity: coords,
                timezone: moment.tz.guess()
            };
            await dispatch({
                type: actions.FETCH_GEO_LOCATION_SUCCESS,
                res: data 
            });
        } catch (error) {
            // if error occurs within geolocation fetch, restore to Montreal settings
            await dispatch({
                type: actions.FETCH_GEO_LOCATION_ERROR,
                error
            });
            console.error(error);
            await dispatch({
                type: actions.CLEAR_GEO_LOCATION,
            });
        }
   }
}

export const getTaskGeoLocation = (locations) => {
    return async(dispatch) => {
        try {
            await dispatch({
                type: actions.FETCH_TASK_GEO_LOCATION,
            });
            let data = [];
            for (let location in locations)  {
                const geoDataRes = await mapboxService.reverseGeocode(locations[location]);
                data.push({
                    location: geoDataRes.data,
                });
            }
            await dispatch({
                type: actions.FETCH_TASK_GEO_LOCATION_SUCCESS,
                data: data 
            });
        } catch(error) {
            console.log(error);
            await dispatch({
                type: actions.FETCH_TASK_GEO_LOCATION_ERROR,
                error
            })
        }
   }
}

export const getTax = (editMode, adminMode = false) => {
    return async(dispatch, getState) => {
        const { bookingReducer, tempBookingReducer, adminBookingReducer } = getState(); 
        try {
            if(adminMode){
                const { viewedBooking } = adminBookingReducer;
                const res = await salesTax.getSalesTax('CA', provinceMap[viewedBooking.dropoffAddress.province]);
                await dispatch({
                    type: actions.GET_TAX,
                    tax: res, 
                });
            }
            else {
                const { dropoff } = editMode? tempBookingReducer: bookingReducer;
                const res = await salesTax.getSalesTax('CA', provinceMap[dropoff.province]);
                await dispatch({
                    type: actions.GET_TAX,
                    tax: res, 
                });
            }
        }
        catch(error) {
            await dispatch({
                type: actions.FETCH_GEO_LOCATION_ERROR,
                error
            });
            throw error;
        }
    } 
}