import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col } from 'antd';

import MainWrapper from '../../components/MainWrapper';
import SquareButton from '../../components/SquareButton';

import { Wrapper, ButtonsWrapper, ContentWrapper, Message } from './styles';

const RequireAuthPage = () => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  let locale = useSelector(({ languageReducer }) => languageReducer.locale);
  const [loggingIn, setLoggingIn] = useState(false);

  const goToLoginPage = () => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname || '/' },
      ui_locales: locale,
    });
  };

  return (
    <MainWrapper logoPositioning="logoLeft" handleLogin={goToLoginPage} doLogin={loggingIn}>
      <Wrapper>
        <ContentWrapper>
          <Message>
            <FormattedMessage id="warning.pleaseAuthenticate" />
          </Message>
          <Wrapper>
            <ButtonsWrapper>
              <Row justify="center">
                <Col span={12}>
                  <SquareButton
                    variant="tertiary"
                    onClick={() => history.push('/')}
                  >
                    <FormattedMessage id="action.back" />
                  </SquareButton>
                </Col>
                <Col span={12}>
                  <SquareButton
                    onClick={() => {
                      setLoggingIn(true);
                    }}
                  >
                    <FormattedMessage id="auth.signin" />
                  </SquareButton>
                </Col>
              </Row>
            </ButtonsWrapper>
          </Wrapper>
        </ContentWrapper>
      </Wrapper>
    </MainWrapper>
  );
};

export default RequireAuthPage;
