import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import RequireAuthPage from '../pages/RequireAuthPage';

const ProtectedRoute = ({ component, ...args }) => {
  const { isAuthenticated } = useAuth0();

  const getAuthPage = () => {
    if (isAuthenticated) {
      return component;
    }
    return RequireAuthPage;
  };

  return <Route component={getAuthPage()} {...args} />;
};

export default ProtectedRoute;
