import React, { useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConfigProvider } from 'antd';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';
import MainBookingPage from '../MainBookingPage';

import ProtectedRoute from '../../auth/protectedRoute';

import { progressSelector } from 'sharedlibs/lib/redux/selectors/booking';
import {
  changeLocale,
  setQueryParams,
  getGeoLocation,
} from 'sharedlibs/lib/redux/actions';

import Loading from '../../components/Loading';
import MovemateMenu from '../../components/MovemateMenu';

import 'antd/dist/antd.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './styles.css';

import { setupIntercom } from '../../intercom-setup';
import zh_CN from 'antd/lib/locale/zh_CN';

const ConfirmationPage = lazy(() => import('../ConfirmationPage'));
const EditBookingPage = lazy(() => import('../EditBookingPage'));
const GuidelinesPage = lazy(() => import('../GuidelinesPage'));
const LocationsPage = lazy(() => import('../LocationsPage'));
// const MainBookingPage = lazy(() => import('../MainBookingPage'));
const MoveTypePage = lazy(() => import('../MoveTypePage'));
const NotFoundPage = lazy(() => import('../NotFoundPage'));
const PaymentPage = lazy(() => import('../PaymentPage'));
const ProfileBookingsPage = lazy(() => import('../ProfileBookingsPage'));
const ProfilePage = lazy(() => import('../ProfilePage'));
const ProfileSettingsPage = lazy(() => import('../ProfileSettingsPage'));
const QuotePage = lazy(() => import('../QuotePage'));
const SchedulePage = lazy(() => import('../SchedulePage'));
const ThankYouPage = lazy(() => import('../ThankYouPage'));
const UserInfoPage = lazy(() => import('../UserInfoPage'));
const localeValues = { 'en': enUS, 'fr': frFR, 'zh': zh_CN };

function App() {
  const { isLoading } = useAuth0();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const progress = useSelector(({ bookingReducer }) =>
    progressSelector(bookingReducer)
  );
  // determine the locale to supply to antd components
  const locale = useSelector(({ languageReducer }) => languageReducer.locale);

  useEffect(() => {
    setupIntercom('q8mula5v', locale);
  }, [locale]);

  useEffect(() => {
    const changeLanguage = () => {
      const lang = new URLSearchParams(search).get('lang');
      if (['zh', 'fr', 'en'].includes(lang)) {
        dispatch(changeLocale(lang));
      }
    };
    return changeLanguage();
  }, [dispatch, search]);

  /**
   * useEffect to add a callback subscription once rehydration is completed.
   * Only specific query parameters should be saved.
   */
  useEffect(() => {
    const params = new URLSearchParams(search, [search]);
    if (params.toString()) {
      const validParams = [
        'discount',
        'pickupFullAddress',
        'pickupApt',
        'pickupFloor',
        'pickupParking',
        'pickupAccessibility',
        'dropoffFullAddress',
        'dropoffApt',
        'dropoffParking',
        'dropoffFloor',
        'dropoffAccessibility',
      ];
      const filteredQueries = {};
      params.forEach((value, key) =>
        validParams.includes(key) ? (filteredQueries[key] = value) : null
      );
      dispatch(setQueryParams(filteredQueries));
    }
  }, []);

  /**
   * effect for retrieving geolocation information of user. If unavailable, default to Montreal settings
   */
  useEffect(() => {
    /**
     * 1. geolocation API call to get long lat and province
     * 2. API call to get static geographic information about province (time zone, tax rate, pricing variables)
     * 3. store geo information to a separate redux store that will not be persisted
     * 4. if error any where in the process, default to Montreal settings
     */
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          getGeoLocation([position.coords.longitude, position.coords.latitude])
        );
      });
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const getRedirect = (Page, mustPassProcesses = [0, 1, 2]) => {
    const canPass = mustPassProcesses.every((step) => {
      if (progress[step] !== 'complete') {
        return false;
      }
      return true;
    });
    return canPass ? <Page /> : <Redirect to="/" />;
  };

  return (
    <ConfigProvider locale={localeValues[locale]}>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path="/"
              exact
              component={MainBookingPage}
            />
            <Route
              path="/confirmation"
              exact
              component={ConfirmationPage}
            />
            <Route
              exact
              path="/guidelines"
            >
              {getRedirect(GuidelinesPage)}
            </Route>
            <Route
              exact
              path="/locations"
              component={LocationsPage}
            />
            <Route
              path="/move-type"
              exact
            >
              {getRedirect(MoveTypePage, [0])}
            </Route>
            <ProtectedRoute
              path="/profile/bookings/:bookingId"
              exact
              component={EditBookingPage}
            />
            <ProtectedRoute
              path="/payment"
              exact
              component={PaymentPage}
            />
            <ProtectedRoute
              path="/profile"
              exact
              component={ProfilePage}
            />
            <ProtectedRoute
              path="/profile/bookings"
              exact
              component={ProfileBookingsPage}
            />
            <ProtectedRoute
              path="/profile/settings"
              exact
              component={ProfileSettingsPage}
            />
            <Route
              exact
              path="/quote"
            >
              {getRedirect(QuotePage)}
            </Route>
            <Route
              path="/schedule"
              exact
            >
              {getRedirect(SchedulePage, [0, 1])}
            </Route>
            <Route
              exact
              path="/thank-you"
              component={ThankYouPage}
            />
            <ProtectedRoute
              path="/user-info"
              exact
              component={UserInfoPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
        <MovemateMenu />
      </Router>
    </ConfigProvider>
  );
}

export default App;
