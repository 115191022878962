import produce from 'immer';
import {
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_ERROR,
  DELETE_TASK,
  DELETE_TASK_ERROR,
  DELETE_TASK_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  GET_TASK_BY_ID,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_ID_ERROR,
  OPS_GET_TASK_BY_ID,
  OPS_GET_TASK_BY_ID_SUCCESS,
  OPS_GET_TASK_BY_ID_ERROR,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERROR,
  GET_INVALID_TASKS,
  GET_INVALID_TASKS_SUCCESS,
  GET_INVALID_TASKS_ERROR,
  GET_INVALID_TASK_BY_ID,
  GET_INVALID_TASK_BY_ID_ERROR,
  GET_INVALID_TASK_BY_ID_SUCCESS,
  GET_MATE_BY_ID,
  GET_MATE_BY_ID_SUCCESS,
  GET_MATE_BY_ID_ERROR,
  UPDATE_MATE,
  UPDATE_MATE_SUCCESS,
  UPDATE_MATE_ERROR,
} from '../constants/adminB2BBooking';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  total: 0,
  tasks: [],
  invalidTasks: [],
  errorMessage: null,
  viewedTask: null,
  mateInfo: null,
};

const adminB2BTASKReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TASKS:
        draft.isLoading = true;
        draft.total = 0;
        draft.tasks = [];
        draft.errorMessage = null;
        break;
      case GET_TASKS_SUCCESS:
        draft.isLoading = false;
        draft.total = action.total;
        draft.tasks = action.data;
        break;
      case GET_TASKS_ERROR:
        draft.isLoading = false;
        draft.total = 0;
        draft.tasks = [];
        draft.errorMessage = action.payload;
        break;
      case GET_INVALID_TASKS:
        draft.isLoading = true;
        draft.invalidTasks = [];
        draft.errorMessage = null;
        break;
      case GET_INVALID_TASKS_SUCCESS:
        draft.isLoading = false;
        draft.invalidTasks = action.data;
        break;
      case GET_INVALID_TASKS_ERROR:
        draft.isLoading = false;
        draft.invalidTasks = [];
        draft.errorMessage = action.payload;
        break;
      case DELETE_TASK:
        draft.isLoading = true;
        draft.errorMessage = null;
        break;
      case DELETE_TASK_SUCCESS:
        draft.isLoading = false;
        break;
      case DELETE_TASK_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;
      case UPDATE_STATUS:
        draft.isLoading = true;
        draft.errorMessage = null;
        break;
      case UPDATE_STATUS_SUCCESS:
        draft.isLoading = false;
        draft.tasks = state.tasks.map((task) => {
          if (task.id === action.id) {
            return { ...task, status: action.status };
          }
          return task;
        });
        break;
      case UPDATE_STATUS_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;
      case GET_TASK_BY_ID:
        draft.isLoading = true;
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = null;
        break;
      case GET_TASK_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedTask = action.data;
        break;
      case GET_TASK_BY_ID_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;
      case GET_INVALID_TASK_BY_ID:
        draft.isLoading = true;
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = null;
        break;
      case GET_INVALID_TASK_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedTask = action.data;
        break;
      case GET_INVALID_TASK_BY_ID_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;
      case OPS_GET_TASK_BY_ID:
        draft.isLoading = true;
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = null;
        break;
      case OPS_GET_TASK_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedTask = action.TASK;
        break;
      case OPS_GET_TASK_BY_ID_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;
      case UPDATE_TASK:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.errorMessage = null;
        break;
      case UPDATE_TASK_SUCCESS:
        draft.viewedTask = action.data;
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case UPDATE_TASK_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = action.payload;
      // eslint-disable-next-line no-fallthrough
      case GET_MATE_BY_ID:
          draft.isLoading = true;
          draft.isSaved = false;
          draft.isWaiting = false;
          draft.errorMessage = null;
          break;
      case GET_MATE_BY_ID_SUCCESS:
          draft.isLoading = false;
          draft.mateInfo = action.data;
          break;
      case GET_MATE_BY_ID_ERROR:
          draft.isLoading = false;
          draft.errorMessage = action.payload;
          break;
      case UPDATE_MATE:
          draft.isSaved = false;
          draft.isLoading = true;
          draft.errorMessage = null;
          break;
      case UPDATE_MATE_SUCCESS:
          draft.mateInfo = action.data;
          draft.isSaved = true;
          draft.isLoading = false;
          break;
      case UPDATE_MATE_ERROR:
          draft.isSaved = false;
          draft.isWaiting = false;
          draft.errorMessage = action.payload;
          break;
        
      default:
        return state;
    }
  });

export default adminB2BTASKReducer;
