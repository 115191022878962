import produce from 'immer';
import {
  GET_FURNITURES,
  GET_FURNITURES_ERROR,
  GET_FURNITURES_SUCCESS,
} from '../constants/furniture';

const initialState = {
  furnitures: [],
  loading: false,
  error: null,
};

const furnitureReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_FURNITURES:
        draft.error = null;
        draft.furnitures = [];
        draft.loading = true;
        break;
      case GET_FURNITURES_ERROR:
        draft.error = action.payload;
        draft.loading = false;
        break;
      case GET_FURNITURES_SUCCESS:
        draft.furnitures = action.payload;
        draft.loading = false;
        break;
      default:
        return state;
    }
  });

export default furnitureReducer;
