import produce from 'immer';
import {
  UPDATE_USER_PROFILE,
  SET_LOADING,
  CLEAR_USER_INFO,
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  GET_OPS,
  GET_OPS_SUCCESS,
  GET_OPS_ERROR,
} from '../constants/ops';

const initialState = {
  firstName: null,
  lastName: null,
  email: null,

  loading: false,
  error: null,
  success: false,

  isLoading: false,
  total: 0,
  ops: [],
};

const opsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_USER:
        draft.loading = true;
        draft.error = null;
        draft.success = false;
        break;
      case CREATE_USER_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case CREATE_USER_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.success = true;
        draft.ops = [...state.ops, action.payload];
        break;

      case GET_OPS:
        draft.isLoading = true;
        draft.success = false;
        draft.total = 0;
        draft.ops = [];
        draft.error = null;
        break;
      case GET_OPS_SUCCESS:
        draft.isLoading = false;
        draft.total = action.total;
        draft.ops = action.data;
        break;
      case GET_OPS_ERROR:
        draft.isLoading = false;
        draft.total = 0;
        draft.ops = [];
        draft.error = action.payload;
        break;

      case UPDATE_USER_PROFILE:
        Object.entries(action.profileData).forEach(([key, value]) => {
          draft[key] = value;
        });
        break;
      case SET_LOADING:
        draft.loading = action.loading;
        break;
      case CLEAR_USER_INFO:
        draft.firstName = null;
        draft.lastName = null;
        draft.email = null;
        draft.loading = false;
        break;
      default:
        return state;
    }
  });

export default opsReducer;
