import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';

import {
  AntRow,
  ContentWrapper,
  Fragment,
  Logo,
  HeaderWrapper,
  ImageMan,
  TitleText,
  Body,
  Header,
  ButtonGroup,
  ButtonGroupWrapper,
  BodyWrapper,
  BodyVerticalWrapper,
  StepperWrapper,
  HeaderAdBlockText,
} from './styles.js';

import Stepper from '../../components/Stepper';
import EstimateBar from '../EstimateBar';

const MainWrapper = ({
  children,
  editMode,
  logoPath,
  logoImage,
  logoPositioning,
  showImage,
  imageDescription,
  nextButton,
  backButton,
  stepper,
  tax,
  tip,
  onStepSelect,
  viewStepperMessage,
  setViewStepperMessage,
  adBlockText,
  handleLogin,
  doLogin,
}) => {
  const { bookingInProgress } = useSelector(
    ({ bookingReducer }) => bookingReducer
  );
  const editInProgress = useSelector(
    ({ tempBookingReducer }) => tempBookingReducer.bookingInProgress
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertOnLeave);
    return () => {
      window.removeEventListener('beforeunload', alertOnLeave);
    };
  }, []);

  const alertOnLeave = useCallback((e) => {
    if ((!editMode && bookingInProgress) || (editMode && editInProgress)) {
      e.preventDefault();
      e.returnValue = '';
    }
  }, []);

  useEffect(() => {
    if (doLogin) {
      window.removeEventListener('beforeunload', alertOnLeave);
      handleLogin();
    }
  }, [doLogin]);

  const renderHeader = () => {
    return (
      <>
        {logoPositioning !== 'none' && (
          <Header>
            <HeaderAdBlockText>{adBlockText}</HeaderAdBlockText>
            <AntRow
              justify={
                logoPositioning !== 'logoCenter' ? 'space-between' : 'center'
              }
              between={logoPositioning !== 'logoCenter' ? 1 : 0}
            >
              <Col>
                <Link to={logoPath}>
                  <Logo
                    src={logoImage}
                    preview={false}
                    between={logoPositioning !== 'logoCenter' ? 1 : 0}
                  />
                </Link>
              </Col>
              {logoPositioning === 'logoXestimate' && (
                <Col>
                  <EstimateBar
                    tax={tax}
                    tip={tip}
                    editMode={editMode}
                  />
                </Col>
              )}
            </AntRow>
          </Header>
        )}
      </>
    );
  };

  const renderImage = () => {
    return (
      <>
        <Fragment marginTop={logoPositioning === 'logoLeft' ? -6 : 0}>
          <Row justify="center">
            <Col>
              <HeaderWrapper>
                {showImage && <ImageMan src="/icons/movemateMan.png" />}
                {imageDescription && (
                  <TitleText>
                    <FormattedMessage id={imageDescription} />
                  </TitleText>
                )}
              </HeaderWrapper>
            </Col>
          </Row>
        </Fragment>
      </>
    );
  };

  const renderStepper = () => {
    return (
      <>
        {stepper && (
          <>
            <StepperWrapper editMode={editMode}>
              {editMode && (
                <p>
                  (<FormattedMessage id="description.bookingEditMode" />)
                </p>
              )}
              <Stepper
                editMode={editMode}
                onStepSelect={stepSelect}
                viewStepperMessage={viewStepperMessage}
                setViewStepperMessage={setViewStepperMessage}
              />
            </StepperWrapper>
          </>
        )}
      </>
    );
  };

  const renderBody = () => {
    return (
      <Body>
        <BodyWrapper>
          <BodyVerticalWrapper>{children}</BodyVerticalWrapper>
        </BodyWrapper>
      </Body>
    );
  };

  const renderButtons = () => {
    return (
      <>
        {logoPositioning !== 'none' && (
          <ButtonGroupWrapper>
            <ButtonGroup>
              {backButton}
              {nextButton}
            </ButtonGroup>
          </ButtonGroupWrapper>
        )}
      </>
    );
  };

  const stepSelect = (step) => {
    onStepSelect(step);
  };

  return (
    <div className="container">
      <ContentWrapper>
        {renderHeader()}
        {renderImage()}
        {renderStepper()}
        {renderBody()}
        {renderButtons()}
      </ContentWrapper>
    </div>
  );
};

MainWrapper.defaultProps = {
  logoPath: '/',
  logoImage: '/movemate_logo.svg',
  logoPositioning: 'logoXestimate',
  showImage: false,
  imageDescription: '',
  nextButton: null,
  backButton: null,
  stepper: false,
  editMode: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStepSelect: () => {},
  viewStepperMessage: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setViewStepperMessage: () => {},
  showRedirectAlert: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLogin: () => {},
  doLogin: false,
};

MainWrapper.propTypes = {
  logoPath: PropTypes.string,
  logoImage: PropTypes.string,
  logoPositioning: PropTypes.oneOf([
    'logoCenter',
    'logoXestimate',
    'logoLeft',
    'none',
  ]),
  showImage: PropTypes.bool,
  imageDescription: PropTypes.string,
  editMode: PropTypes.bool,
  onStepSelect: PropTypes.func,
  viewStepperMessage: PropTypes.bool,
  setViewStepperMessage: PropTypes.func,
  handleLogin: PropTypes.func,
  doLogin: PropTypes.bool,
};

export default MainWrapper;
