import axios from "axios";

export default class DiscountService {
	/**
	 * Create a new discount object in the database.
	 * @param {object} discountData the details of the new discount code
	 * @param {string} discountData.code the code of the discount
	 * @param {boolean} discountData.isPercentage determines whether the discount is rebate (false) or percentage (true)
	 * @param {object[]} discountData.timePeriods the time periods in which the discount code is valid
	 * @param {string} discountData.timePeriods[].startDate the starting date of the time period
	 * @param {string} discountData.timePeriods[].endDate the end date of the time period
	 * @param {number} discountData.timePeriods[].value the value that is valid in the given time period
	 * @param {string} accessToken the JSON wbe token of the current active user
	 */
	async createDiscount(discountData, accessToken) {
		const response = await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/discounts`,
			discountData,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	}

	/**
	 * Get a list of discounts (determined by page and perPage numbers) from the database.
	 * @param {number} page the page number of the discounts to be returned
	 * @param {number} perPage the number of discounts that will be returned
	 * @param {string} accessToken the JSON web token of the current active user
	 */
	async getDiscounts(page, perPage, accessToken) {
		const response = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/discounts?page=${page}&per_page=${perPage}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	}

	async getSearchDiscounts (value, page, perPage, accessToken) {
		return axios
		.get(`${process.env.REACT_APP_BACKEND_URL}/discounts/search?value=${value}&page=${page}&per_page=${perPage}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
		.then((resp) => resp.data)
		.catch((err) => console.log(err));
	}

	/**
	 * Get a discount by id.
	 * @param {string} discountId the id of the discount that is requested
	 * @param {string} accessToken the JSON web token of the current active user
	 */
	async getDiscountById(discountId, accessToken) {
		const response = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/discounts/${discountId}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	}

	/**
	 * Get a discount by its code.
	 * @param {string} discountCode the code of the discount
	 * @param {string} accessToken the JSON web token of the current active user
	 */
	async getDiscountByCode(discountCode) {
		const response = await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/discount`,
			discountCode
		);
		return response.data;
	}

	/**
	 * Update the details of an existing discount.
	 * @param {string} discountId the id of the discount
	 * @param {object} updateData the updated details of the discount
	 * @param {string} accessToken the JSON web token of the current active user
	 */
	async updateDiscount(discountId, updateData, accessToken) {
		const response = await axios.put(
			`${process.env.REACT_APP_BACKEND_URL}/discounts/${discountId}`,
			updateData,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	}

	/**
	 * Delete an existing discount using its id.
	 * @param {string} discountId the id of the discount
	 * @param {string} accessToken the JSON web token of the current active user
	 */
	async deleteDiscount(discountId, accessToken) {
		await axios.delete(
			`${process.env.REACT_APP_BACKEND_URL}/discounts/${discountId}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
	}
}
