const DOCUMENT = 'B2B_';

export const GET_TASKS = DOCUMENT + 'GET_TASKS';
export const GET_TASKS_SUCCESS = DOCUMENT + 'GET_TASKS_SUCCESS';
export const GET_TASKS_ERROR = DOCUMENT + 'GET_TASKS_ERROR';
export const GET_INVALID_TASKS = DOCUMENT + 'GET_INVALID_TASKS';
export const GET_INVALID_TASKS_SUCCESS = DOCUMENT + 'GET_INVALID_TASKS_SUCCESS'
export const GET_INVALID_TASKS_ERROR = DOCUMENT + 'GET_INVALID_TASKS_ERROR'
export const DELETE_TASK = DOCUMENT + 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = DOCUMENT + 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_ERROR = DOCUMENT + 'DELETE_TASK_ERROR';
export const UPDATE_STATUS = DOCUMENT + 'UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = DOCUMENT + 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_ERROR = DOCUMENT + 'UPDATE_STATUS_ERROR';
export const GET_TASK_BY_ID = DOCUMENT + 'GET_TASK_BY_ID';
export const GET_TASK_BY_ID_SUCCESS = DOCUMENT + 'GET_TASK_BY_ID_SUCCESS';
export const GET_TASK_BY_ID_ERROR = DOCUMENT + 'GET_TASK_BY_ID_ERROR';
export const OPS_GET_TASK_BY_ID = DOCUMENT + 'OPS_GET_TASK_BY_ID';
export const OPS_GET_TASK_BY_ID_SUCCESS = DOCUMENT + 'OPS_GET_TASK_BY_ID_SUCCESS';
export const OPS_GET_TASK_BY_ID_ERROR = DOCUMENT + 'OPS_GET_TASK_BY_ID_ERROR';
export const UPDATE_TASK = DOCUMENT + 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = DOCUMENT + 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = DOCUMENT + 'UPDATE_TASK_ERROR';
export const UPDATE_INVALID_TASK = DOCUMENT + 'UPDATE_INVALID_TASK';
export const UPDATE_INVALID_TASK_SUCCESS = DOCUMENT + 'UPDATE_INVALID_TASK_SUCCESS';
export const UPDATE_INVALID_TASK_ERROR = DOCUMENT + 'UPDATE_INVALID_TASK_ERROR';
export const GET_INVALID_TASK_BY_ID = DOCUMENT + 'GET_INVALID_TASK_BY_ID';
export const GET_INVALID_TASK_BY_ID_SUCCESS = DOCUMENT + 'GET_INVALID_TASK_BY_ID_SUCCESS';
export const GET_INVALID_TASK_BY_ID_ERROR = DOCUMENT + 'GET_INVALID_TASK_BY_ID_ERROR';
export const GET_MATE_BY_ID = DOCUMENT + 'GET_MATE_BY_ID';
export const GET_MATE_BY_ID_SUCCESS = DOCUMENT + 'GET_MATE_BY_ID_SUCCESS';
export const GET_MATE_BY_ID_ERROR = DOCUMENT + 'GET_MATE_BY_ID_ERROR';
export const UPDATE_MATE = DOCUMENT + 'UPDATE_MATE';
export const UPDATE_MATE_SUCCESS = DOCUMENT + 'UPDATE_MATE_SUCCESS';
export const UPDATE_MATE_ERROR = DOCUMENT + 'UPDATE_MATE_ERROR';