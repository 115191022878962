import {
  CLEAR_PARTNER,
  CREATE_PARTNER,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_ERROR,
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_ERROR,
  GET_PARTNER_BY_ID,
  GET_PARTNER_BY_ID_SUCCESS,
  GET_PARTNER_BY_ID_ERROR,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_ERROR,
} from '../constants/partner';
import PartnerService from '../../services/PartnerService';

const partnerService = new PartnerService();

/**
 * Reset partner viewedPartner
 */
export const resetPartner = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_PARTNER });
  };
};

/**
 * Gets the list of partners
 * @param {string} accessToken
 */
export const getPartners = (accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PARTNER,
      });
      const resp = await partnerService.getPartner(accessToken);
      dispatch({
        type: GET_PARTNER_SUCCESS,
        partners: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_PARTNER_ERROR,
        payload: error,
      });
    }
  };
};

/**
 * Gets a partner by ID
 * @param {string} partnerId
 * @param {string} accessToken
 */
export const getPartnerById = (partnerId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PARTNER_BY_ID,
      });
      const resp = await partnerService.getPartnerById(partnerId, accessToken);
      dispatch({
        type: GET_PARTNER_BY_ID_SUCCESS,
        data: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_PARTNER_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Creates a partner
 * @param {obj} partnerData The data to create partner
 *                      name {string} the partner name
 *                      addresses {array[Address]} the address of the partner
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createPartner = (partnerData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_PARTNER,
      loading: true,
    });
    try {
      const resp = await partnerService.createPartner(partnerData, accessToken);
      dispatch({
        type: CREATE_PARTNER_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_PARTNER_ERROR,
        error: err,
      });
    }
  };
};
/**
 * Update a partner
 * @param {obj} partnerData The data to update partner
 *                      id {string} the partner id
 *                      name {string} the partner name
 *                      addresses {array[Address]} the address of the partner
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const updatePartner = (partnerId, partnerData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PARTNER,
      loading: true,
    });
    try {
      const resp = await partnerService.updatePartner(partnerId, partnerData, accessToken);
      dispatch({
        type: UPDATE_PARTNER_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PARTNER_ERROR,
        error: err,
      });
    }
  };
};