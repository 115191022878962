import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  locationProgress,
  moveTypeProgress,
  scheduleProgress,
} from 'sharedlibs/lib/redux/selectors/booking';

import MainWrapper from '../../components/MainWrapper';

import { CirclesWrapper, MainCircularButton } from './styles';
import SquareButton from '../../components/SquareButton';
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import {Alert} from 'antd'

export default function MainBookingPage() {
  const history = useHistory();
  const steps = useSelector(({ bookingReducer }) => ({
    step1: locationProgress(bookingReducer),
    step2: moveTypeProgress(bookingReducer),
    step3: scheduleProgress(bookingReducer),
  }));
  const intl = useIntl();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: 'Main booking page',
      page_location: '/',
      page_path: '/',
      send_to: 'UA-167184748-1'
    })
  }, [])

  const bannerClose = () => {
    localStorage.setItem('setadblockbanner', 'false')
  }

  return (
    <MainWrapper
      stepper
      adBlockText= {
      <AdBlockDetectedWrapper>
      {localStorage.hasOwnProperty('setadblockbanner') ? '' : <Alert
        type='warning'
        description={intl.formatMessage({id: 'warning.adBlockDetectedMessage'})}
        showIcon
        closable
        afterClose={bannerClose}
        />}
      </AdBlockDetectedWrapper>}
      nextButton={
        <SquareButton
          disabled={!Object.values(steps).every((step) => step)}
          onClick={() => {
            history.push('/quote');
          }}
        >
          <FormattedMessage id="action.next" />
        </SquareButton>
      }
    >
      <CirclesWrapper>
        <MainCircularButton
          type="normal"
          active={steps.step1}
          customClass="firstCircle"
          imagePath="/icons/map.png"
          title={<FormattedMessage id="model.locations" />}
          description={<FormattedMessage id="model.pickupDropoff" />}
          onClick={() => {
            history.push('/locations');
          }}
          clickable
        />
        <MainCircularButton
          type="normal"
          active={steps.step2}
          disabled={!steps.step2 && !steps.step1}
          customClass="secondCircle"
          imagePath="/icons/box.png"
          title={<FormattedMessage id="model.moveType" />}
          description={<FormattedMessage id="description.detailsItems" />}
          onClick={() => {
            if (!(!steps.step2 && !steps.step1)) {
              history.push('/move-type');
            }
          }}
          clickable
        />
        <MainCircularButton
          type="normal"
          active={steps.step3}
          disabled={!steps.step3 && !steps.step2}
          customClass="thirdCircle"
          imagePath="/icons/calendar.png"
          title={<FormattedMessage id="model.schedule" />}
          description={<FormattedMessage id="description.dateTime" />}
          onClick={() => {
            if (!(!steps.step3 && !steps.step2 && !steps.step1)) {
              history.push('/schedule');
            }
          }}
          clickable
        />
      </CirclesWrapper>
    </MainWrapper>
  );
}
