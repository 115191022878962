import axios from 'axios';
import { provinceMap } from '../../utils/provinces';

export default class MapboxService {
  constructor() {
    this.mapboxRouteEndpoint = 'https://api.mapbox.com';
  }

  /**
   * Builds string of semicolon separated coordinate pairs
   *   see: https://docs.mapbox.com/api/navigation/directions/#retrieve-directions
   * @param {Array} coordinates array of coordinate objects
   * @returns {string} string of semicolon separated coordinate pairs
   */
  buildCoordString(coordinates) {
    let coordString = '';

    // iterate over and extract longitude and latitude and add pair to string
    Object.values(coordinates).forEach((coordObj, idx) => {
      coordString += `${coordObj.lng},${coordObj.lat}`;
      // add seperator to end of each pair except for last pair
      if (idx < coordinates.length - 1) {
        coordString += ';';
      }
    });

    return coordString;
  }

  /**
   * Gets the structure needed by the backend for the locations, throws an error if out of bounds province
   * @param {Object} geoData The data obtained from calling the reverse geolocation endpoint from mapbox
   */
  parseReverseGeo(geoData) {
    try {
      const resp = {};
      if (geoData && geoData.data) {
        const features = geoData.data.features;
        features.forEach((feature) => {
          const placeType = feature.place_type[0];
          if (placeType === 'address') {
            resp.unit = feature.address;
            resp.street = feature.text;
          } else if (placeType === 'postcode') {
            resp.postalCode = feature.text;
          } else if (placeType === 'place') {
            resp.city = feature.text;
          } else if (placeType === 'region') {
            resp.province = feature.text;
          } else if (placeType === 'country') {
            resp.country = feature.text;
          }
        });
      }
      

      // check if we got a province / check if we provide service to that province
      if(!resp.province || !Object.keys(provinceMap).includes(resp.province)) {
        throw Error('Province not serviceable yet, defaulting to Quebec'); 
      }
  
      return resp;

    } catch (error) {
      console.log(error, 'erroring'); 
    }
  }

  /**
   * Get route from mapbox directions endpoint
   * @param {Object} from coordinate pair of the 'from' location
   * @param {float} from.lng the longitude of the 'from' location
   * @param {float} from.lat the latitude of the 'from' location
   * @param {Object} to coordinate pair of the 'to' location
   * @param {float} to.lng the longitude of the 'to' location
   * @param {float} to.lat the latitude of the 'to' location
   * @returns {Object} contains the route data (route, duration, and distance) and the pickup/dropoff address
   */
  async getRouteData(from, to) {
    const coordString = this.buildCoordString([
      from.coordinates,
      to.coordinates,
    ]);
    
    const route = await axios.get(
      `${this.mapboxRouteEndpoint}/directions/v5/mapbox/driving/${coordString}?annotations=distance,speed&geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
    );
    return {
      pickup: from.location,
      dropoff: to.location,
      route,
    };
  }

  /**
   *
   * @param {*} searchQuery
   * @param {string} types type of geographic data to search for
   * @param {string} country country (in ISO 3166 format) in which we limit suggestions
   * @param {string} proximity sort/order suggestions by proximity of GPS coordinates
   */
  forwardGeocodeSuggestions(searchQuery, types, country, proximity, bbox) {
    const config = {
      params: {
        access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        types,
        country,
        proximity,
        bbox,
      },
    };

    const geocodingRequest = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json`;
    return axios.get(geocodingRequest, config);
  }

  /**
   * function used to query the mapbox api for reverse geocoding a location. Will throw error if coordinates are not available.
   * @param {list} coords the given coordinates of a user. 
   * @returns result of reverse geocoding from api
   */
  reverseGeocode(coords) {
    try {
      if(!coords)
        throw Error('coordinates unavailable, defaulting to Montreal.');

     const config = {
      params: {
          access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
        }
      }
    
    const reverseGeocodeRequest = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[0]},${coords[1]}.json?limit=1&types=region`;
    return axios.get(reverseGeocodeRequest, config); 
    } catch (error) {
      console.error(error);       
    }
  }
}
