/**
 * Function to remove leading and trailing spaces in strings, inside of an object
 * @param {Object} obj The object containing strings to be removed of whitespace from both ends of a string with trim()
 * @returns The final object which have strings cleaned of whitespace from both ends of a string with trim()
 */
export const trimObjectStrings = (obj) => {
  const finalObj = {};

  // eslint-disable-next-line unused-imports/no-unused-vars
  Object.keys(obj).forEach(function (key, index) {
    if (typeof obj[key] === 'string' || obj[key] instanceof String) {
      // we trim spaces from strings
      finalObj[key] = obj[key].trim();

    } else if (
      typeof obj === 'object' &&
      !Array.isArray(obj) &&
      obj !== null
    ) {

      // recursively go through nested object, looking for strings to process
      finalObj[key] = trimObjectStrings(obj[key]);

    } else {

      // we skip processing other types of variable and just put it directly to the final object
      finalObj[key] = obj[key];

    }
  });

  return finalObj;
};