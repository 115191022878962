import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu, Badge } from 'antd';

import { changeLocale } from 'sharedlibs/lib/redux/actions';

import { Wrapper, ProfileBtn, LanguageBadgeContainer } from './styles';

const MovemateMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLocale = useSelector(
    ({ languageReducer }) => languageReducer.locale || 'en',
  );
  const { isAuthenticated } = useAuth0();
  const intl = useIntl();

  const languageMenu = () => {
    return (
      <Menu
        onClick={({ key }) => {
          dispatch(changeLocale(key));
        }}
        selectedKeys={[currentLocale]}
      >
        <Menu.Item key="zh">ZH</Menu.Item>
        <Menu.Item key="fr">FR</Menu.Item>
        <Menu.Item key="en">EN</Menu.Item>
      </Menu>
    );
  };

  const renderLanguageToggle = () => {
    return (
      <LanguageBadgeContainer
        overlay={languageMenu}
        placement="topRight"
        trigger="click"
      >
        <Badge ref={null} count={currentLocale.toUpperCase()} offset={[10, 10]}>
          <span />
        </Badge>
      </LanguageBadgeContainer>
    );
  };

  return (
    <Wrapper>
      <div>{renderLanguageToggle()}</div>
      <ProfileBtn
        onClick={() => {
          history.push('/profile');
        }}
      >
        <img src="/icons/profile.svg" alt="profile" />
        <Badge ref={null} count={
          (isAuthenticated && intl.formatMessage({id: "sidebar.profile"})) ||
          intl.formatMessage({id: "sidebar.signIn"})} offset={[-25, 15]}>
          <span />
        </Badge>
      </ProfileBtn>
    </Wrapper>
  );
};

export default MovemateMenu;
