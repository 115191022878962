import produce from 'immer';
import {
  GET_RATE,
  GET_RATE_ERROR,
  GET_RATE_SUCCESS,
} from '../constants/rate';

const initialState = {
  // backend rate "2_or_3_mates_threshold_cuft"
  levelOneThreshold: 0,

  // backend rate "3_mates_threshold_cuft"
  levelTwoThreshold: 0,

  loading: false,
  error: null,
};

const rateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_RATE:
        draft.error = null;
        draft.loading = true;
        break;
      case GET_RATE_ERROR:
        draft.error = action.payload;
        draft.loading = false;
        break;
      case GET_RATE_SUCCESS:
        Object.entries(action).forEach(([key, value]) => {
          draft[key] = value;
        });
        draft.loading = false;
        break;
      default:
        return state;
    }
  });

export default rateReducer;
