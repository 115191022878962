import styled from 'styled-components';
import { Row, Image } from 'antd';

export const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);

  @media (max-width: 1024px) {
    min-height: 150px;
  }
`;

export const Logo = styled(Image)`
  width: 200px;

  @media (max-width: 1024px) {
    width: 120px;
  }
`;

export const StepperWrapper = styled.div`
  margin-top: ${(props) => `${props.editMode ? -4.5 : -3}em`};
  text-align: center;

  @media (max-width: 1024px) {
    margin-top: -3em;
  }

  p {
    font-size: 1em;
    margin: 0;
    padding: 0;
    color: var(--tertiary-color);

    @media (max-width: 1024px) {
      font-size: 0.8em;
    }
  }
`;

export const Header = styled.div`
  flex: 0 0 12%;
`;

export const Body = styled.div`
  position: relative;
  margin-top: 1.2em;
  flex: 1;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BodyVerticalWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;

  @media (max-width: 1024px) {
    padding-bottom: 16vh;
  }
`;

export const BodyWrapper = styled.div`
  top: 0;
  position: absolute;
  display: table;
  height: 100%;
  width: 100%;
`;

export const ButtonGroupWrapper = styled.div`
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    background: var(--container-color);
    width: 100%;
    z-index: 2;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 25px 0px;
  padding: 0 90px;

  & > button {
    margin: 0 0.5rem;
    max-width: 200px;
  }
`;

export const AntRow = styled(Row)`
  min-height: ${(props) => `${props.between ? 120 : 140}px`};
`;

export const Fragment = styled.div`
  margin-top: ${(props) => `${props.marginTop}%`};
`;

export const HeaderWrapper = styled.div`
  text-align: center;
`;

export const HeaderAdBlockText = styled.h1`
`;

export const ImageMan = styled.img`
  max-height: 24vh;
`;

export const TitleText = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: var(--tertiary-color);
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 1.5em;
  }
`;
