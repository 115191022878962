import axios from 'axios';

export default class RateService {
  async getTemplates(accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/templates`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async getTemplate(messageType, language, accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/templates/${messageType}/${language}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }

  async updateTemplate(messageType, language, updatedData, accessToken) {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/templates/${messageType}/${language}`,
        updatedData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  }
}



