const DOCUMENT = 'BOOKING_';

export const GET_BOOKINGS = DOCUMENT + 'GET_BOOKINGS';
export const GET_BOOKINGS_SUCCESS = DOCUMENT + 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_ERROR = DOCUMENT + 'GET_BOOKINGS_ERROR';
export const DELETE_BOOKING = DOCUMENT + 'DELETE_BOOKING';
export const DELETE_BOOKING_SUCCESS = DOCUMENT + 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_ERROR = DOCUMENT + 'DELETE_BOOKING_ERROR';
export const UPDATE_STATUS = DOCUMENT + 'UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = DOCUMENT + 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_ERROR = DOCUMENT + 'UPDATE_STATUS_ERROR';
export const GET_BOOKING_BY_ID = DOCUMENT + 'GET_BOOKING_BY_ID';
export const GET_BOOKING_BY_ID_SUCCESS = DOCUMENT + 'GET_BOOKING_BY_ID_SUCCESS';
export const GET_BOOKING_BY_ID_ERROR = DOCUMENT + 'GET_BOOKING_BY_ID_ERROR';
export const OPS_GET_BOOKING_BY_ID = DOCUMENT + 'OPS_GET_BOOKING_BY_ID';
export const OPS_GET_BOOKING_BY_ID_SUCCESS = DOCUMENT + 'OPS_GET_BOOKING_BY_ID_SUCCESS';
export const OPS_GET_BOOKING_BY_ID_ERROR = DOCUMENT + 'OPS_GET_BOOKING_BY_ID_ERROR';
export const UPDATE_BOOKING = DOCUMENT + 'UPDATE_BOOKING';
export const UPDATE_BOOKING_SUCCESS = DOCUMENT + 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_ERROR = DOCUMENT + 'UPDATE_BOOKING_ERROR';
