import produce from 'immer';
import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_ERROR,
  DELETE_BOOKING,
  DELETE_BOOKING_ERROR,
  DELETE_BOOKING_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  GET_BOOKING_BY_ID,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_ERROR,
  OPS_GET_BOOKING_BY_ID,
  OPS_GET_BOOKING_BY_ID_SUCCESS,
  OPS_GET_BOOKING_BY_ID_ERROR,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR,
} from '../constants/adminBooking';

const initialState = {
  isLoading: false,
  isSaved: false,
  isWaiting: false,
  total: 0,
  bookings: [],
  errorMessage: null,
  viewedBooking: null,
};

const adminBookingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_BOOKINGS:
        draft.isLoading = true;
        draft.total = 0;
        draft.bookings = [];
        draft.errorMessage = null;
        break;
      case GET_BOOKINGS_SUCCESS:
        draft.isLoading = false;
        draft.total = action.total;
        draft.bookings = action.data;
        break;
      case GET_BOOKINGS_ERROR:
        draft.isLoading = false;
        draft.total = 0;
        draft.bookings = [];
        draft.errorMessage = action.payload;
        break;

      case DELETE_BOOKING:
        draft.isLoading = true;
        draft.errorMessage = null;
        break;
      case DELETE_BOOKING_SUCCESS:
        draft.isLoading = false;
        break;
      case DELETE_BOOKING_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;

      case UPDATE_STATUS:
        draft.isLoading = true;
        draft.errorMessage = null;
        break;
      case UPDATE_STATUS_SUCCESS:
        draft.isLoading = false;
        draft.bookings = state.bookings.map((booking) => {
          if (booking.id === action.id) {
            return { ...booking, status: action.status };
          }
          return booking;
        });
        break;
      case UPDATE_STATUS_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;

      case GET_BOOKING_BY_ID:
        draft.isLoading = true;
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = null;
        break;
      case GET_BOOKING_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedBooking = action.booking;
        break;
      case GET_BOOKING_BY_ID_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;

      case OPS_GET_BOOKING_BY_ID:
        draft.isLoading = true;
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = null;
        break;
      case OPS_GET_BOOKING_BY_ID_SUCCESS:
        draft.isLoading = false;
        draft.viewedBooking = action.booking;
        break;
      case OPS_GET_BOOKING_BY_ID_ERROR:
        draft.isLoading = false;
        draft.errorMessage = action.payload;
        break;

      case UPDATE_BOOKING:
        draft.isSaved = false;
        draft.isWaiting = true;
        draft.errorMessage = null;
        break;
      case UPDATE_BOOKING_SUCCESS:
        draft.viewedBooking = action.booking;
        draft.isSaved = true;
        draft.isWaiting = false;
        break;
      case UPDATE_BOOKING_ERROR:
        draft.isSaved = false;
        draft.isWaiting = false;
        draft.errorMessage = action.payload;
        break;
      default:
        return state;
    }
  });

export default adminBookingReducer;
