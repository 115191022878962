import styled from 'styled-components';
import { Dropdown } from 'antd';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 5;
`;

export const ProfileBtn = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  user-select: none;
  cursor: pointer;
  width: 60px;
  height: 60px;

  & > img {
    width: 60px;
    height: 60px;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

export const LanguageBadgeContainer = styled(Dropdown)`
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;
