import {
    GET_PARTNER_INVOICE,
    GET_PARTNER_INVOICE_ERROR,
    GET_PARTNER_INVOICE_SUCCESS
} from "../constants/partnerInvoices"

import PartnerInvoiceService from "../../services/partnerInvoiceService"

const partnerInvoiceService = new PartnerInvoiceService

export const getAllPartnerInvoices = (partnerID, page, perPage, accessToken) =>    {
    return async (dispatch) =>  {
        dispatch({
            type: GET_PARTNER_INVOICE,
        });
        try {
            const resp = await partnerInvoiceService.getAllInvoices(
                partnerID,
                page,
                perPage, 
                accessToken
            );
            dispatch({
                type: GET_PARTNER_INVOICE_SUCCESS,
                payload: resp.data.data,
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: GET_PARTNER_INVOICE_ERROR,
                payload: error,
            });
        }
       
    }
}


export const getFilteredInvoices = (partnerID, filterDict, page, perPage, accessToken) =>   {
    return async (dispatch) =>  {
        dispatch({
            type: GET_PARTNER_INVOICE,
        });
        try {
            const resp = await partnerInvoiceService.getFilteredInvoices(
                partnerID, 
                filterDict,
                page,
                perPage, 
                accessToken
            );
            dispatch({
                type: GET_PARTNER_INVOICE_SUCCESS,
                payload: resp.data.data,
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: GET_PARTNER_INVOICE_ERROR,
                payload: error,
            });
        }
    }
}