import axios from 'axios';

export default class BookingService {
  /**
   * Gets the list of ops user
   * @param {int} page the current page to be searched
   * @param {int} perPage the amount of items returned
   * @param {string} accessToken
   */
  getOps(page, perPage = 10, accessToken) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/ops?page=${page}&per_page=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((resp) => resp.data);
  }

  /**
   * Creates a new user in auth0
   * @param {obj} userInfo The basic ops user info
   *                      email {string} the ops user email
   *                      firstName {string} the ops member first name
   *                      lastName {string} the ops member family name
   *                      language {string} the language of the email that will be sent
   * @param {string} accessToken The current user access token to ping the backend
   */
  createOpsMember(userInfo, accessToken) {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/ops`, userInfo, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
