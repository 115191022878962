import styled from 'styled-components';

export const Tax = styled.div`
  font-size: 1rem;
  color: var(--tertiary-color);
  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 0.9rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  padding: 12px;
  border-radius: 10px 0px 0px 10px;
  color: #686868;
  width: fit-content;

  @media (max-width: 768px) {
    padding: 7px;
    margin-top: 10px;
    width: 150px;
  }
`;

export const InfoWrapper = styled.div`
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const TitleText = styled.span`
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ValueTextWrapper = styled.div`
  font-size: 2.1rem;
  line-height: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
`;

export const CoinText = styled.span`
  font-weight: 300;
`;

export const ValueText = styled.span`
  font-weight: 500;
  color: var(--secondary-color);
`;
