import produce from 'immer';

import {
    FETCH_GEO_LOCATION,
    CLEAR_GEO_LOCATION,
    GET_TAX,
    FETCH_GEO_LOCATION_SUCCESS,
    FETCH_GEO_LOCATION_ERROR,
    FETCH_TASK_GEO_LOCATION,
    FETCH_TASK_GEO_LOCATION_SUCCESS,
    FETCH_TASK_GEO_LOCATION_ERROR,
} from '../constants/geo';

/** Initial state refers to default Montreal settings */
const initialState = {
    province: 'QC',
    timezone: 'America/Toronto',
    proximity: [-73.646116, 45.498647],
    tax: {
        type: "gst+qst",
        rate: 0.14975,
    },
    isLoading: false, 
    error: null,
    stillLoading: false,
    locations: null
}

const geoReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case FETCH_GEO_LOCATION:
                draft.isLoading = true;
                draft.error = null
                break;
            case FETCH_GEO_LOCATION_SUCCESS:
                draft.isLoading = false;
                Object.entries(action.res).forEach(([key, val]) => {
                    draft[key] = val;
                });
                break; 
            case FETCH_GEO_LOCATION_ERROR:
                draft.isLoading = false;
                draft.error = action.error;
                break;
            case FETCH_TASK_GEO_LOCATION:
                draft.stillLoading = true;
                draft.error = null
                break;
            case FETCH_TASK_GEO_LOCATION_SUCCESS:
                draft.stillLoading = false;
                draft.locations = action.data
                break; 
            case FETCH_TASK_GEO_LOCATION_ERROR:
                draft.stillLoading = false;
                draft.error = action.error;
                break;
            case GET_TAX:
                draft.tax = action.tax;
                break;
            case CLEAR_GEO_LOCATION:
                state = initialState;
                return state;
            default:
                return state;
        }
    })
}

export default geoReducer;