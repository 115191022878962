const DOCUMENT = 'TEMP_BOOKING';

export const TEMP_UPDATE_BOOKING = `${DOCUMENT}_UPDATE_BOOKING`;
export const TEMP_SET_ESTIMATE = `${DOCUMENT}_SET_ESTIMATE`;
export const TEMP_SET_DISCOUNT_CODE = `${DOCUMENT}_SET_DISCOUNT_CODE`;
export const TEMP_CLEAR_BOOKING_INFO = `${DOCUMENT}_CLEAR_BOOKING_INFO`;
export const TEMP_GET_BOOKING = `${DOCUMENT}_GET_BOOKING`;
export const TEMP_GET_BOOKING_ERROR = `${DOCUMENT}_GET_BOOKING_ERROR`;
export const TEMP_GET_BOOKING_SUCCESS = `${DOCUMENT}_GET_BOOKING_SUCCESS`;
export const TEMP_EDIT_BOOKING = `${DOCUMENT}_EDIT_BOOKING`;
export const TEMP_EDIT_BOOKING_ERROR = `${DOCUMENT}_EDIT_BOOKING_ERROR`;
export const TEMP_EDIT_BOOKING_SUCCESS = `${DOCUMENT}_EDIT_BOOKING_SUCCESS`;
export const UPDATE_TEMP_EDIT_BOOKING_SUCCESS = `${DOCUMENT}_UPDATE_TEMP_EDIT_BOOKING_SUCCESS`;
