const DOCUMENT = 'INVOICE_';

export const GET_INVOICE = DOCUMENT + 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = DOCUMENT + 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_ERROR = DOCUMENT + 'GET_INVOICE_ERROR';
export const UPDATE_INVOICE = DOCUMENT + 'UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = DOCUMENT + 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = DOCUMENT + 'UPDATE_INVOICE_ERROR';
export const TOGGLE_EDIT = DOCUMENT + 'TOGGLE_EDIT';
export const SEND_INVOICE = DOCUMENT + 'SEND_INVOICE';
export const SEND_INVOICE_SUCCESS = DOCUMENT + 'SEND_INVOICE_SUCCESS';
export const SEND_INVOICE_ERROR = DOCUMENT + 'SEND_INVOICE_ERROR';
export const CLEAR_SEND_INVOICE = DOCUMENT + 'CLEAR_SEND_INVOICE';
