const mapboxState = {
  loading: false,
  route: '',
  duration: 0,
  distance: 0,
  error: '',
  pickup: {
    unit: '',
    street: '',
    aptUnit: '',
    postalCode: '',
    city: '',
    province: '',
    country: '',
  },
  dropoff: {
    unit: '',
    street: '',
    aptUnit: '',
    postalCode: '',
    city: '',
    province: '',
    country: '',
  },
};

export default mapboxState;
