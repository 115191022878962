const DOCUMENT = 'DISCOUNT_';

export const CREATE_DISCOUNT = DOCUMENT + 'CREATE_DISCOUNT';
export const CREATE_DISCOUNT_SUCCESS = DOCUMENT + 'CREATE_DISCOUNT_SUCCESS';
export const CREATE_DISCOUNT_ERROR = DOCUMENT + 'CREATE_DISCOUNT_ERROR';
export const GET_DISCOUNTS = DOCUMENT + 'GET_DISCOUNTS';
export const GET_DISCOUNTS_SUCCESS = DOCUMENT + 'GET_DISCOUNTS_SUCCESS';
export const GET_DISCOUNTS_ERROR = DOCUMENT + 'GET_DISCOUNTS_ERROR';
export const DELETE_DISCOUNT = DOCUMENT + 'DELETE_DISCOUNT';
export const DELETE_DISCOUNT_SUCCESS = DOCUMENT + 'DELETE_DISCOUNT_SUCCESS';
export const DELETE_DISCOUNT_ERROR = DOCUMENT + 'DELETE_DISCOUNT_ERROR';
export const GET_DISCOUNT_BY_ID = DOCUMENT + 'GET_DISCOUNT_BY_ID';
export const GET_DISCOUNT_BY_ID_SUCCESS = DOCUMENT + 'GET_DISCOUNT_BY_ID_SUCCESS';
export const GET_DISCOUNT_BY_ID_ERROR = DOCUMENT + 'GET_DISCOUNT_BY_ID_ERROR';
export const UPDATE_DISCOUNT = DOCUMENT + 'UPDATE_DISCOUNT';
export const UPDATE_DISCOUNT_SUCCESS = DOCUMENT + 'UPDATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_ERROR = DOCUMENT + 'UPDATE_DISCOUNT_ERROR';
export const GET_DISCOUNT_BY_CODE = DOCUMENT + 'GET_DISCOUNT_BY_CODE';
export const GET_DISCOUNT_BY_CODE_SUCCESS = DOCUMENT + 'GET_DISCOUNT_BY_CODE_SUCCESS';
export const GET_DISCOUNT_BY_CODE_ERROR = DOCUMENT + 'GET_DISCOUNT_BY_CODE_ERROR';
export const RESET_ERROR = DOCUMENT + 'RESET_ERROR';
