import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_OPS,
  GET_OPS_SUCCESS,
  GET_OPS_ERROR,
} from '../constants/ops';
import OpsService from '../../services/OpsService';

const opsService = new OpsService();

/**
 * Gets the list of ops user
 * @param {int} page the current page to be searched
 * @param {int} perPage the amount of items returned
 * @param {string} accessToken
 */
export const getOps = (page = 0, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_OPS,
      });
      const resp = await opsService.getOps(page, perPage, accessToken);
      dispatch({
        type: GET_OPS_SUCCESS,
        total: resp.total,
        data: resp.data,
      });
    } catch (error) {
      dispatch({
        type: GET_OPS_ERROR,
        payload: error,
      });
    }
  };
};

/**
 * Creates an ops user
 * @param {obj} userInfo The basic ops user info
 *                      email {string} the ops user email
 *                      firstName {string} the ops member first name
 *                      lastName {string} the ops member family name
 *                      language {string} the language of the email that will be sent
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createOpsUser = (userInfo, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_USER,
      loading: true,
    });
    try {
      const resp = await opsService.createOpsMember(userInfo, accessToken);
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_USER_ERROR,
        error: err,
      });
    }
  };
};
