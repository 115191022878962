import React from 'react';
import ReactDOM from 'react-dom';
import ReactBreakpoints from 'react-breakpoints';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import App from './pages/App';
import Auth0ProviderWithHistory from './auth/auth0ProviderWithHistory';
import LanguageProvider from './components/LanguageProvider';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from 'sharedlibs/lib';
import './index.css';
import { PersistGate } from 'redux-persist/es/integration/react';

// Uncomment for push to dev or production
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscapeSmall: 924,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
};

ReactDOM.render(
  <ReactBreakpoints breakpoints={breakpoints}>
    <Router>
      <Auth0ProviderWithHistory>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
          >
            <LanguageProvider>
              <App />
            </LanguageProvider>
          </PersistGate>
        </Provider>
      </Auth0ProviderWithHistory>
    </Router>
  </ReactBreakpoints>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
