const DOCUMENT = 'TASK_';

export const GET_TASKS = DOCUMENT + 'GET_TASKS';
export const GET_TASKS_SUCCESS = DOCUMENT + 'GET_TASKS_SUCCESS';
export const GET_TASKS_ERROR = DOCUMENT + 'GET_TASKS_ERROR';

export const GET_TASK_BY_ID = DOCUMENT + 'GET_TASK_BY_ID';
export const GET_TASK_BY_ID_SUCCESS = DOCUMENT + 'GET_TASK_BY_ID_SUCCESS';
export const GET_TASK_BY_ID_ERROR = DOCUMENT + 'GET_TASK_BY_ID_ERROR';

export const GET_ONFLEET_TASKS = DOCUMENT + 'GET_ONFLEET_TASKS';
export const GET_ONFLEET_TASKS_SUCCESS = DOCUMENT + 'GET_ONFLEET_TASKS_SUCCESS';
export const GET_ONFLEET_TASKS_ERROR = DOCUMENT + 'GET_ONFLEET_TASKS_ERROR';

export const CHANGE_ONFLEET_TASK_SIZE = DOCUMENT + 'CHANGE_ONFLEET_TASK_SIZE';
export const CHANGE_ONFLEET_TASK_TIME_RANGE = DOCUMENT + 'CHANGE_ONFLEET_TASK_TIME_RANGE';

export const SEND_SMS_TASK = DOCUMENT + 'SEND_SMS_TASK';
export const SEND_SMS_TASK_ERROR = DOCUMENT + 'SEND_SMS_TASK_ERROR';

export const GET_ARRIVAL_TASKS = DOCUMENT + 'GET_ARRIVAL_TASKS';
export const GET_ARRIVAL_TASKS_SUCCESS = DOCUMENT + 'GET_ARRIVAL_TASKS_SUCCESS';
export const GET_ARRIVAL_TASKS_ERROR = DOCUMENT + 'GET_ARRIVAL_TASKS_ERROR';

export const GET_CONTACTS = DOCUMENT + 'GET_CONTACTS'
export const GET_CONTACTS_SUCCESS = DOCUMENT + 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_ERROR = DOCUMENT + 'GET_CONTACTS_ERROR'

export const GET_CONTACTS_BY_NUMBER = DOCUMENT + 'GET_CONTACTS_BY_NUMBER'
export const GET_CONTACTS_BY_NUMBER_SUCCESS = DOCUMENT + 'GET_CONTACTS_BY_NUMBER_SUCCESS'
export const GET_CONTACTS_BY_NUMBER_ERROR = DOCUMENT + 'GET_CONTACTS_BY_NUMBER_ERROR'

export const SEND_CUSTOM_SMS = DOCUMENT + 'SEND_CUSTOM_SMS'
export const SEND_CUSTOM_SMS_SUCCESS = DOCUMENT + 'SEND_CUSTOM_SMS_SUCCESS'
export const SEND_CUSTOM_SMS_ERROR = DOCUMENT + 'SEND_CUSTOM_SMS_ERROR'