import styled from 'styled-components';

import CircularButton from '../../components/CircularButton';

export const CirclesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 738px) {
    flex-direction: column;
    align-items: center;
    transform: scale(0.9);
  }
`;

export const MainCircularButton = styled(CircularButton)`
  height: 10px;
`;
