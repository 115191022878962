import axios from 'axios';
import { saveAs } from 'file-saver';

export default class InvoiceService {
  /**
   * Gets an invoice given the bookingId
   * @param {string} bookingId The booking id related to the invoice
   * @param {string} accessToken The current user access token to ping the backend
   */
  getInvoiceByBookingId(bookingId, accessToken) {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/invoices/${bookingId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  /**
   * Updates the content of an invoice
   * @param {string} invoiceId id of invoice
   * @param {Object} invoice contains all the fields of the invoice to update with
   * @param {string} accessToken JWT of the current user
   * @returns {obj} of response from backend
   */
  async updateInvoice(invoiceId, invoice, accessToken) {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/invoices/${invoiceId}`,
      invoice,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return res.data;
  }

  /**
   * Sends an email with the invoice linked to the booking
   * @param {string} bookingId The booking id linked to the invoice to be sent
   * @param {float} rate The local tax rate of the booking
   * @param {string} accessToken JWT of the current user
   * @returns the invoice id
   */
  async sendInvoice(bookingId, rate, accessToken) {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/invoices/send/${bookingId}`,
      {
        "rate": rate,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return res.data;
  }

  /**
   * Downloads a specific invoice as a pdf with the on client changes made if any.
   * @param {Object} invoiceDetails  The invoice object that will be downloaded
   * @param {string} accessToken JWT of the current user
   */
  async downloadInvoice(invoiceDetails, accessToken) {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/invoices/download/${invoiceDetails.bookingId}`,
      invoiceDetails,
      {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer'
      }
    );

    saveAs(new Blob([await res.data], {
      type: 'application/pdf;charset=utf-8'
    }),
    `${invoiceDetails.bookingId}-invoice.pdf`
    )
  }
}
