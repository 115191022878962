import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

// configuration object for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'userReducer',
    'adminAppReducer',
    'adminBookingReducer',
    'adminPartnerReducer',
    'adminMateReducer',
    'invoiceReducer',
    'opsReducer',
    'furnitureReducer',
    'tempBookingReducer',
    'tempMapboxReducer',
    'queryReducer',
    'geoReducer',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
