import produce from 'immer';
import { CHANGE_LOCALE } from '../constants/language';

const getBrowserLanguage = () => {
  const { navigator } = window;
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.userLanguage ||
    'en'
  );
};

const chooseDefaultLanguage = () => {
  const browserLanguage = getBrowserLanguage();
  if (browserLanguage.indexOf('fr') >= 0) {
    return 'fr';
  }
  return 'en';
};

const initialState = {
  locale: chooseDefaultLanguage() || 'en',
};

const languageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.locale;
        break;

      default:
        return state;
    }
  });

export default languageReducer;
